import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,CircularProgress } from '@mui/material';
import DataNotFoundImage from "../../assets/no_data_found_image.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSpfLookUpMutation } from "src/services/tool-service.js";

const SPFCheckerPage = () => {
  const [domainDetails, setDomainDetails] = useState([]);
  const [spfLookUp] = useSpfLookUpMutation();
  
  // Combined IPv4 and IPv6 regex
  const ipRegex = /^(?:\d{1,3}\.){3}\d{1,3}$|^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/;

  const validationSchema = () => {
    let schema = Yup.object({
      domain: Yup.string()
      .max(255)
      .required("Domain is required")
      .matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, "Please add a valid Domain address"),
      ip: Yup.string()
      .max(255)
      .matches(ipRegex, 'Invalid IP address (IPv4 or IPv6)')
    });
  
    return schema;
  };

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
        const result = await spfLookUp(values).unwrap();
        if(result.message === "Fetched Error"){
          setDomainDetails([{noDomainFound:`No valid SPF record found for domain ${formik?.values?.domain}`}]);

        } else {
          const spfParts = result.spfRecord.join(' ').split(' ');
          const spfDataInArray = [];
        
          spfParts.forEach((part) => {
            if (part.startsWith('v=')) {
              spfDataInArray.push({
                prefix: 'v',
                type: 'version',
                value: part.split('=')[1],
                prefixDesc: '-',
                description: 'The SPF record version',
              });
            } else if (part.startsWith('include:')) {
              spfDataInArray.push({
                prefix: '+',
                type: 'include',
                value: part.split(':')[1],
                prefixDesc: 'Pass',
                description: "The specified domain is searched for an 'allow'",
              });
            } else if (part.startsWith('-all')) {
              spfDataInArray.push({
                prefix: '-',
                type: 'all',
                value: '-',
                prefixDesc: 'SoftFail',
                description: 'Always matches. It goes at the end of your record',
              });
            } else if (part.startsWith('~all')) {
              spfDataInArray.push({
                prefix: '~',
                type: 'all',
                value: '-',
                prefixDesc: 'SoftFail',
                description: 'Always matches. It goes at the end of your record',
              });
            } else if (part.startsWith('?all')) {
              spfDataInArray.push({
                prefix: '?',
                type: 'all',
                value: '-',
                prefixDesc: 'SoftFail',
                description: 'Always matches. It goes at the end of your record',
              });
            }
          });
          setDomainDetails([{spfRecord:result.spfRecord,spfRecordArray:spfDataInArray}]);  
        }  
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message });
    }
  };
  
  const formik = useFormik({
    initialValues: {
      domain: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers, true);
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message });
      }
      formik.resetForm();
    },
  });

  return (
    <Box sx={{ px: { sm: '7%' }, py: { sm: 5 }, p: 4, fontFamily: 'Inter' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main'}}>
        SPF RECORD CHECKER
      </Typography>
      
      <Typography  color="primary.main" sx={{ fontSize: "0.8rem" }}>
        Find SPF records for specific domains, helping prevent email spoofing and phishing attacks.
      </Typography>

      {/* Spf Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'baseline', flexDirection: { xs: 'column', sm: 'row' }, mt: {xs: '24px', sx:'24px', md:3, lg:3, xl:3} }}>
          <Box sx={{ display: 'flex', gap: { sm: 1 }, flexDirection: { xs: 'column', sm: 'row' }, mb: { xs: 0, sm: 0 },width:{xs:'100%',sm:'auto'} }}>
            <TextField
              onChange={formik.handleChange}
              error={!!(formik.touched.domain && formik.errors.domain)}
              helperText={formik.touched.domain && formik.errors.domain}
              onBlur={formik.handleBlur}
              name="domain"
              value={formik?.values?.domain}
              variant="outlined"
              placeholder="Enter a domain name"
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { sm: '21vw', xs: 'auto' }, 
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
                paddingRight:{sm:'0px'}

              }}
            />
            <TextField
              onChange={formik.handleChange}
              error={!!(formik.touched.ip && formik.errors.ip)}
              helperText={formik.touched.ip && formik.errors.ip}
              onBlur={formik.handleBlur}
              name="ip"
              value={formik?.values?.ip}
              variant="outlined"
              placeholder="Enter a IP address(optional)"
              sx={{
                borderColor: 'gray.300',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  width: { sm: '21vw', xs: 'auto' }, 
                  maxHeight: '44px',
                  color: 'primary.main'
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: '8px', sm: '11px' },
              }}
            />
          </Box>
           <Button
            variant="contained"
            color="primary"
            sx={{
              ml: [0,4],
              px: 4,
              py: 2,
              width: { sm: 'fit-content', xs: '100%' },
              padding: '10px',
              marginLeft: { xs: '7px', sm: '4' },
              marginTop: { xs: 2, sm: 0 } ,
              maxHeight: '44px',
              cursor: formik?.values?.domain ? 'pointer' : 'not-allowed',
            }}
            disabled={!formik?.values?.domain}
            type="submit"
          >
            {formik.isSubmitting ? (
              <CircularProgress size={20} color="inherit" />
              ) : (
              <>
                Look up          
              </>
            )}
          </Button>      
        </Box>
      </form>

      { domainDetails.length > 0 && domainDetails[0] && (
        <Box
          mt={4}
          sx={{
            my:2,
            mt: { sm: 6 },
            backgroundColor: domainDetails[0].noDomainFound ? '#ffcbd1' : '#d6e9c6',
            p: 3,
            borderRadius: '8px',
            mb: 4,
            color: '#3c763d',
          }}
        >
          <Typography
            sx={{
              backgroundColor: domainDetails[0].noDomainFound ? '#ffcbd1' : '#d6e9c6',
              padding: '8px',
              borderRadius: '8px',
              color: domainDetails[0].noDomainFound ? '#EF4444' : '#3c763d',
            }}
          >
            {domainDetails[0].spfRecord
              ? domainDetails[0].spfRecord.join(' ')
              : domainDetails[0].noDomainFound
              ? domainDetails[0].noDomainFound
              : ''}
          </Typography>
        </Box>
      )}

      {/* Table Display */} 
      <Box
        sx={{
          height: 'auto',
          mt: { sm: 6, xs: 6 },
          border: 8,
          borderColor: '#ECEDF5',
          borderRadius: '8px',
        }}
      >
      <TableContainer
        component={Paper}
      >
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#F6F6FA', color: '#9295A7', fontSize: '1.2rem',fontWeight: 'medium'  }}>
              <TableCell  sx={{ px: 4, py: 2 }}>Prefix</TableCell>
              <TableCell  sx={{ px: 4, py: 2 }}>Type</TableCell>
              <TableCell  sx={{ px: 4, py: 2 }}>Value</TableCell>
              <TableCell  sx={{ px: 4, py: 2 }}>PrefixDesc</TableCell>
              <TableCell  sx={{ px: 4, py: 2 }}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {domainDetails.length !== 0 && domainDetails[0].spfRecordArray ? (
              domainDetails[0].spfRecordArray.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.prefix}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell>{row.value}</TableCell>
                  <TableCell>{row.prefixDesc}</TableCell>
                  <TableCell>{row.description}</TableCell>
                </TableRow>
              ))
            ) :  (
              <TableRow>
                <TableCell colSpan={5} sx={{ px: 4, py: 2, textAlign: 'center' }}>
                  <Typography sx={{ mt: { sm: '5vh', xs: '2vh', fontSize: '0.8rem' } }}>
                    { domainDetails[0]?.noDomainFound ? domainDetails[0].noDomainFound : 'Simply enter a domain or IP address, click "Look up", and get instant results.'}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: '10vh' }}>
                    <img src={DataNotFoundImage} alt="Doc Icon" />
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
    </Box>
  );
};

export default SPFCheckerPage;