import { useEffect } from 'react';
import { useGetCurrentPlanQuery } from "src/services/billing-service.js";
import { useGetMeAndWorkspaceQuery, useGetMeQuery } from "src/services/user-service";
import { useSelector } from "react-redux";

const UserVerification = () => {
  const  { data: data, refetch: refetchData } = useGetMeAndWorkspaceQuery();
  const { data: currentPlan } = useGetCurrentPlanQuery();
  const freeAccountsLimit = useSelector((state) => state.accounts);
  // Helper functions to determine user type
  const isAppSumoUser = () => {
    return data?.workspace && data?.workspace.assignedPlan && data?.workspace.assignedPlan.length > 0 && !data?.workspace.isAppSumoRefund;
  };

  const isEarlyBirdAccessUser = () => {
    return data?.workspace && data?.workspace.isEarlyBirdAccessUser && !data?.workspace.isEarlyBirdDealRefund;
  };

  const isPaidUser = () => {
    return data?.workspace && currentPlan && (currentPlan.subscription?.sendingWarmup?.expiresAt || currentPlan.subscription?.leads?.expiresAt);
  };

  const checkIfDisableButton = () => {
    if (isAppSumoUser() || isEarlyBirdAccessUser() || isPaidUser()) {
      return false; // Button should not be disabled
    }
    // User is a free trial user
    if (freeAccountsLimit.length >= 2) {
      return true; // Disable button
    }
    return false; // Less than 2 accounts, do not disable button
  };

  useEffect(() => {
    refetchData();
  }, [currentPlan, freeAccountsLimit]);

  const userDetails = {
    isAppSumoUser: isAppSumoUser(),
    isEarlyBirdAccessUser: isEarlyBirdAccessUser(),
    isPaidUser: isPaidUser(),
    isFreeUser: !isAppSumoUser() && !isEarlyBirdAccessUser() && !isPaidUser(),
    shouldDisableButton: checkIfDisableButton(),
    accountsLimit: freeAccountsLimit.length
  };

  return userDetails;
};

export default UserVerification;
