import React, { useState } from "react";
import { Box, Typography, TextareaAutosize, Grid } from "@mui/material";
import { SPAM_WORDS } from "./spamwords";

const SpamChecker = () => {
  const [spamWordsList, setSpamWordsList] = useState([]);

  const [wordDetails, setWordDetails] = useState({ wordCount: 0, readTime: "", overAllScore: "-" });

  const [userInput, setUserInput] = useState("");
  const [spamCounter, setSpamCounter] = useState(0);

  const getSpamWords = (message) => {
    let spamArray = [];
    const words = message.split(/\s+/);

    for (let i = 0; i < words.length; i++) {
      for (let j = 0; j < SPAM_WORDS.length; j++) {
        if (new RegExp(SPAM_WORDS[j].highlight).test(words[i])) {
          spamArray.push(SPAM_WORDS[j]);
          break;
        }
      }
    }

    return spamArray;
  };

  const detectSpam = (message) => {
    // Clean up and split the message into words
    const words = message.split(/\s+/);
    const wordCount = words.length;

    // Calculate read time (assuming 200 words per minute)
    const readTime = Math.ceil(wordCount / 200);

    // Count occurrences of specific keyword types
    const spamArray = getSpamWords(message);
    setSpamWordsList(spamArray);
    // console.log("spamWordsList => ",spamWordsList);

    const urgencyCount = spamArray?.filter((e) => e.category === "urgency")?.length || 0;
    const shadyCount = spamArray?.filter((e) => e.category === "shady")?.length || 0;
    const overpromiseCount = spamArray?.filter((e) => e.category === "overpromise")?.length || 0;
    const moneyCount = spamArray?.filter((e) => e.category === "money")?.length || 0;
    const unnaturalCount = spamArray?.filter((e) => e.category === "unnatural")?.length || 0;

    setSpamCounter({
      urgencyCount,
      shadyCount,
      overpromiseCount,
      moneyCount,
      unnaturalCount,
    });

    // Calculate overall score based on counts
    let overAllScore = "-";
    const totalSpamIndicators = urgencyCount + shadyCount + overpromiseCount + unnaturalCount;

    if (totalSpamIndicators >= 6) {
      overAllScore = "Poor";
    } else if (totalSpamIndicators >= 3) {
      overAllScore = "Avg";
    } else {
      overAllScore = "Good";
    }

    // Set all word details in a single state update
    setWordDetails({
      wordCount,
      readTime,
      overAllScore,
    });

    setUserInput(message);
  };

  // Function to render highlighted spam words
  const renderHighlightedText = (text) => {
    const colorMap = {
      urgency: "red",
      shady: "#f9a8d4",
      overpromise: "#fdba74",
      unnatural: "gray",
      money: "yellow",
    };

    const words = text.split(/\s+/);
    return words.map((word, index) => {
      const matchedSpamWord = spamWordsList.find((spamWord) =>
        new RegExp(spamWord.highlight).test(word)
      );

      return (
        <span
          key={index}
          style={{
            backgroundColor: matchedSpamWord ? colorMap[matchedSpamWord?.category] : "transparent",
            fontWeight: matchedSpamWord ? "bold" : "normal",
          }}
        >
          {word}{" "}
        </span>
      );
    });
  };

  return (
    <Box sx={{ padding: { xs: "1rem", sm: "6rem 7%" }, fontFamily: "Inter" }}>
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Spam Checker
      </Typography>

      <Grid container spacing={2} direction={{ xs: "column", sm: "row" }} alignItems="flex-start">
        {/* Textarea */}
        <Grid item xs={12} sm={8} id={`spam-detect-text`} sx={{ width: "100%" }}>
          <TextareaAutosize
            minRows={17}
            placeholder="Copy/paste an email message to detect and remove spam words."
            style={{
              width: "100%",
              padding: "1rem",
              border: "1px solid #ccc",
              borderRadius: "0.5rem",
              maxHeight: "300px",
              overflowY: "auto",
            }}
            onChange={(e) => {
              detectSpam(e.target.value);
            }}
          />

          {userInput && (
            <Box
              sx={{
                padding: "1rem",
                border: "1px solid #ccc",
                borderRadius: "0.5rem",
                backgroundColor: "#f5f5f5",
                marginTop: "1rem",
                whiteSpace: "pre-wrap",
                maxHeight: "300px",
                overflowY: "auto",
              }}
            >
              {renderHighlightedText(userInput)}
            </Box>
          )}
        </Grid>

        {/* Score and Details */}
        <Grid item xs={12} sm={4}>
          <Box sx={{ padding: "1rem", borderRadius: "0.5rem" }}>
            <Typography variant="h5" fontWeight="600">
              Overall score:{" "}
              <span
                style={{
                  color:
                    wordDetails?.overAllScore === "Poor"
                      ? "red"
                      : wordDetails?.overAllScore === "Good"
                      ? "green"
                      : "inherit",
                }}
              >
                {wordDetails?.overAllScore ? wordDetails?.overAllScore : "-"}
              </span>
            </Typography>
            <Typography sx={{ marginTop: "1rem" }}>
              Words: {wordDetails?.wordCount ? wordDetails?.wordCount : "0"}
            </Typography>
            <Typography sx={{ marginTop: "1rem" }}>
              Read time: {wordDetails?.readTime + " Min" ? wordDetails?.readTime + " Min" : "-"}
            </Typography>

            <Box sx={{ marginTop: "1.5rem" }}>
              {/* Urgency */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Box
                  sx={{
                    backgroundColor: "red",
                    width: "1rem",
                    height: "1rem",
                    borderRadius: "50%",
                    marginRight: "0.5rem",
                  }}
                />
                <Typography>Urgency ({spamCounter.urgencyCount})</Typography>
              </Box>

              {/* Shady */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Box
                  sx={{
                    backgroundColor: "#f9a8d4",
                    width: "1rem",
                    height: "1rem",
                    borderRadius: "50%",
                    marginRight: "0.5rem",
                  }}
                />
                <Typography>Shady ({spamCounter.shadyCount})</Typography>
              </Box>

              {/* Overpromise */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Box
                  sx={{
                    backgroundColor: "#fdba74",
                    width: "1rem",
                    height: "1rem",
                    borderRadius: "50%",
                    marginRight: "0.5rem",
                  }}
                />
                <Typography>Overpromise ({spamCounter.overpromiseCount})</Typography>
              </Box>

              {/* Unnatural */}
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Box
                  sx={{
                    backgroundColor: "gray",
                    width: "1rem",
                    height: "1rem",
                    borderRadius: "50%",
                    marginRight: "0.5rem",
                  }}
                />
                <Typography>Unnatural ({spamCounter.unnaturalCount})</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SpamChecker;
