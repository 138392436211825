export const config = {
  GOOGLE_SHEET_KEY: process.env.REACT_APP_GOOGLE_SHEET_API_KEY,
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  INTERCOM_PROXY_BASE_URL: process.env.REACT_APP_INTERCOM_PROXY_BASE_URL,
  // Google OAuth
  GOOGLE_OAUTH_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  GOOGLE_OAUTH_AUTH_URL: "https://accounts.google.com/o/oauth2/v2/auth",
  GOOGLE_OAUTH_REDIRECT_URL: process.env.REACT_APP_GOOGLE_REDIRECT_URL,
  GOOGLE_OAUTH_SCOPE: [
    "https://mail.google.com/",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email",
  ].join(" "),
  // Microsoft OAuth
  MICROSOFT_OAUTH_CLIENT_ID: process.env.REACT_APP_MICROSOFT_OAUTH_CLIENT_ID,
  MICROSOFT_OAUTH_REDIRECT_URI: process.env.REACT_APP_MICROSOFT_OAUTH_REDIRECT_URI,
  MICROSOFT_OAUTH_AUTHORIZATION_URL:
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
  MICROSOFT_OAUTH_SCOPE: [
    "openid",
    "email",
    "profile",
    "offline_access",
    "https://outlook.office.com/IMAP.AccessAsUser.All",
    "https://outlook.office.com/SMTP.Send",
  ].join(" "),

  SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID,
  SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID,
  SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID,
  SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID,
  SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID:
    process.env.REACT_APP_SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID,
  SENDING_WARMUP_YEARLY_SCALE_PRICE_ID: process.env.REACT_APP_SENDING_WARMUP_YEARLY_SCALE_PRICE_ID,
  LEADS_MONTHLY_SKYROCKET_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_SKYROCKET_PRICE_ID,
  LEADS_MONTHLY_GROWTH_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_GROWTH_PRICE_ID,
  LEADS_MONTHLY_SCALE_PRICE_ID: process.env.REACT_APP_LEADS_MONTHLY_SCALE_PRICE_ID,
  LEADS_YEARLY_SKYROCKET_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_SKYROCKET_PRICE_ID,
  LEADS_YEARLY_GROWTH_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_GROWTH_PRICE_ID,
  LEADS_YEARLY_SCALE_PRICE_ID: process.env.REACT_APP_LEADS_YEARLY_SCALE_PRICE_ID,
  TINYMCE_EDITOR_API: process.env.REACT_APP_TINYMCE_API,
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
};
export const planNames = {
  [config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID]: "Monthly Starter",
  [config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID]: "Monthly Growth",
  [config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID]: "Monthly Infinity",
  [config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID]: "Yearly Growth",
  [config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID]: "Yearly Starter",
  [config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID]: "Yearly Infinity",
  [config.LEADS_MONTHLY_SKYROCKET_PRICE_ID]: "Monthly Skyrocket Leads",
  [config.LEADS_MONTHLY_GROWTH_PRICE_ID]: "Monthly Growth Leads",
  [config.LEADS_MONTHLY_SCALE_PRICE_ID]: "Monthly 10X Scale Leads",
  [config.LEADS_YEARLY_SKYROCKET_PRICE_ID]: "Yearly Skyrocket Leads",
  [config.LEADS_YEARLY_GROWTH_PRICE_ID]: "Yearly Skyrocket Leads",
  [config.LEADS_YEARLY_SCALE_PRICE_ID]: "Yearly 10X Scale Leads",
};