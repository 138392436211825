import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const GrammarChecker = () => {
  const [textInput, setTextInput] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCheckGrammar = async () => {
    setLoading(true);
    try {
      const result = await axios.post("https://tools.smartlead.ai/api/grammarChecker", {
        textInput: textInput,
      });
      if (result.status === 200) {
        const data = JSON.parse(result.data.response);
        setResponse(data.text);
      } else {
        setResponse("An error occurred while checking grammar Please Try Again with other words.");
      }
    } catch (error) {
      console.error("Error checking grammar:", error);
      setResponse("An error occurred while checking grammar Please Try Again with other words.");
    }
    setLoading(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(response);
  };

  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Box
        sx={{
          px: { sm: "7%" },
          py: { sm: 6 },
          fontFamily: "Inter",
          width: { sm: "100%", xs: "100%" },
        }}
      >
        <Typography variant="h5" fontWeight="bold" sx={{ color: "primary.main", pb: 2 }}>
          Free Online Grammar Checker
        </Typography>
        <Typography sx={{ color: "primary.main", mb: 6 }}>
          Ensure your writing is mistake-free with the help of this online grammar checker. Paste
          your text or start typing below to pinpoint grammatical errors, spelling mistakes, and
          punctuation inaccuracies.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { sm: "row", xs: "column" },
            gap: { sm: 2, xs: 4 },
            alignItems: "center",
          }}
        >
          <TextField
            multiline
            minRows={5}
            fullWidth
            variant="outlined"
            sx={{
              borderColor: "gray.300",
              "& .MuiOutlinedInput-root": {
                "&:hover fieldset": {
                  borderColor: "link.main",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "link.main",
                },
              },
              color: "primary.main",
            }}
            onChange={(e) => {
              setTextInput(e.target.value);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: { sm: "15%", xs: "50%" },
              height: { sm: "auto", xs: "56px" },
              cursor: textInput ? "pointer" : "not-allowed",
            }}
            disabled={!textInput}
            onClick={handleCheckGrammar}
          >
            {/* Show loader */}
            {loading ? <CircularProgress size={24} color="inherit" /> : "Check"}
          </Button>
        </Box>
        {/* </Box> */}

        {response !== "" && (
          <Box mt={4}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: { sm: "84%", xs: "100%" } }}
            >
              <Typography variant="h6" fontWeight="bold" color="primary" my={2}>
                Grammatically Corrected Text:
              </Typography>
              <Tooltip title="Copy">
                <IconButton
                  onClick={handleCopy}
                  sx={{ color: "#6E58F1", "&:hover": { color: "#7c68f1" } }}
                >
                  <ContentCopyIcon fontSize="small" />
                  <Typography variant="body2" ml={1} sx={{ cursor: "pointer" }}>
                    Copy
                  </Typography>
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              component="pre"
              sx={{
                backgroundColor: "grey.50",
                border: "1px solid",
                borderColor: "grey.300",
                borderRadius: 1,
                p: 2,
                mt: 2,
                width: { sm: "84%", xs: "100%" },
                color: "grey.800",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
              }}
            >
              {response}
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default GrammarChecker;
