import { toast } from "react-hot-toast";
import _ from 'lodash';
import { emailRegex } from "src/utils/util";

export const selections = [
    {
      label: "Email",
      value: "email",
    },
    {
      label: "First Name",
      value: "firstName",
    },
    {
      label: "Last Name",
      value: "lastName",
    },
    {
      label: "Company Name",
      value: "companyName",
    },
    {
      label: "Phone",
      value: "phone",
    },
    {
      label: "Website",
      value: "website",
    },
    {
      label: "Location",
      value: "location",
    },
    {
      label: "iceBreaker",
      value: "iceBreaker",
    },
    {
      label: "Title",
      value: "title",
    },
    {
      label: "Link",
      value: "link",
    },
    {
      label: "Custom Variable",
      value: "Custom_Variable",
    },
    {
      label: "Do not import",
      value: "DO_NOT_IMPORT",
    },
  ];
  
export  const selectionsFilters = [
    {
      label: "Compnay",
      value: "company",
    },
    {
      label: "Do not import",
      value: "DO_NOT_IMPORT",
    },
  ];

export const handleDialogUploadAllClick = async ({      
    setOpen,
    isUploading,
    setSnackbarOpen,
    setSnackbarMsg,
    leads,
    checkDuplicates,
    verifyLeads,
    bounceCredits,
    stats,
    onLeadsCreate,
    campaign,
    createLeads,
    invalidEmails,
    totalLeads,
    workspaceId
    }) => {
    if(leads[0]?.email == undefined){
      toast.error('Email parameter is mandatory...')
      return;
    }
    setOpen(false);
    if (isUploading) return;
    try {
      let totalCreatedLeads = [];
      let blockLeads = 0;
      let globalBlockedLeads = 0;
      let statusLeadWorker = false;

      if (verifyLeads && totalLeads?.length > bounceCredits) {
        toast.error(`You don't have enough lead verification credits.`);
        return;
      }
      setSnackbarOpen(true);

      setSnackbarMsg(`Uploading leads...`);
      const limit = 500;
      const total = totalLeads?.length;
      let skippedLeads;
      for (let i = 0; i < total; i += limit) {
        const start = i;
        const end = i + limit > total ? i + (total % limit) : i + limit;

        const { message, createdLeads, blockLeadsCount, globalBlockedLeadsCount, leadWorker } = await createLeads({
          id: campaign._id,
          data: {
            leads: checkDuplicates ? stats.leads.slice(start, end) : leads.slice(start, end),
            checkDuplicates,
            verifyLeads,
            workspaceId: workspaceId
          },
        }).unwrap();
        blockLeads = blockLeadsCount;
        globalBlockedLeads = globalBlockedLeadsCount;
        totalCreatedLeads = [...totalCreatedLeads, ...createdLeads];
        statusLeadWorker = leadWorker;

        setSnackbarMsg(`${end} / ${total} leads uploading`);
        const [addedLeadsPart, skippedLeadsPart] = message.split(" and ");
        skippedLeads = skippedLeadsPart;
      }
      onLeadsCreate(true);
     

      setTimeout(() => {
        setSnackbarOpen(false);
        if (!statusLeadWorker) {
          toast.success(`${totalCreatedLeads.length} out of ${total} uploaded successfully!`);
        }
        // toast.success(`Leads import in process with verification`);

        if (blockLeads > 0) {
          toast.error(`${blockLeads} contacts were blocked by your blocklist.`)
        }
        if (globalBlockedLeads > 0) {
          toast.error(`${globalBlockedLeads} bounced leads skipped.`)
        }
        if (invalidEmails?.length > 0) {
          toast.error(`${invalidEmails?.length} email(s) are invalid.`)
        }
        if (statusLeadWorker && totalCreatedLeads.length > 0) {
          setTimeout(() => {
            onLeadsCreate(true);
          }, 4000);
        }
      }, 1500);
    } catch (error) {
      toast.error(error.data.error.message);
    }
  };

export const handleUploadAllClick = async ({      
    checkDuplicates, 
    verifyLeads,
    bounceCredits,
    leads, 
    duplicateCheck, 
    setStats, 
    setOpen,
    campaign,
    workspaceId
    }) => {
    try {
      const { createdLeads } = await duplicateCheck({
        id: campaign._id,
        data: {
          leads: leads,
          checkDuplicates,
          verifyLeads,
          stats: true,
          workspaceId: workspaceId
        },
      }).unwrap();
      setStats(createdLeads);
      setOpen(true);
    } catch (error) {
      toast.error(error.data.error.message);
    }
  };

export const handleSelectTypeChange = ({      
    column, 
    selection,
    index, 
    selectionValue,
    setSelectionValue,
    csvData,
    filter,
    setCsvData,
    onLeadsUpdate
    }) => {
    const newSelectionValue = [...selectionValue];
    let textTitle;
    if (selection.textTitle) {
      textTitle = selection.textTitle
      delete selection['textTitle'];
    }
    newSelectionValue[index] = selection;
    setSelectionValue(newSelectionValue);
    let newData = {};
    const updatedCsvData = csvData.map((row) =>
      row.map((cell) => {
        if (textTitle) {
          if (cell.column === column) {
            newData = { ...cell, type: selection.value };
            row.map((innerCell) => {
              if (innerCell.column === textTitle) {           
                newData = { ...newData, textValue: innerCell.value, textType: textTitle };
              }   
            })
          } 
          else {
            return filter ? { ...cell, type: "DO_NOT_IMPORT" } : cell;
          }
          if(newData?.column && newData?.textType){
            return newData;
          }
        } else {
          if (cell.column === column) {
            return { ...cell, type: selection.value };
          } else {
            return filter ? { ...cell, type: "DO_NOT_IMPORT" } : cell;
          }
        }
      })
    );
    setCsvData(updatedCsvData);
    onLeadsUpdate(updatedCsvData);
  };

export const updateLeads = ({      
    data,
    selections,
    filter,
    setLeads
    }) => {
    const validFieldNames = new Set(["companyName", "firstName", "location", "title", "lastName", "email", "iceBreaker", "phone", "website", "link", "DO_NOT_IMPORT", "Custom_Variable"]);
    const validColumnNames = new Set(
      selections.map(item => item.value.toLowerCase().trim())
    );

    const updateLeads = data?.map((row) => {
      const extractedLead = {};
      const remainingLead = [];
      row.forEach((cell) => {
        const fieldName = cell?.type;
        const columnName = cell?.column.toLowerCase().trim();

        if (fieldName !== "DO_NOT_IMPORT") {
          const isFieldValid = filter ? fieldName === 'company' : validFieldNames.has(fieldName);

          if (isFieldValid) {
            if ((fieldName === "Custom_Variable" || fieldName === "link") && !validColumnNames.has(columnName)) {
              const remainingData = {};
              remainingData.variableTitle = columnName
              remainingData.variableValue = cell?.value;

              if (cell?.textType && cell?.textValue) {
                remainingData.type = 'link'
                remainingData.textType = cell?.textType
                remainingData.textValue = cell?.textValue
              }
              remainingLead.push(remainingData);
            } else {
              extractedLead[fieldName] = cell?.value;
            }
          }
        }
      });
      return {
        ...extractedLead,
        remainingData: remainingLead,
      };
    });

    setLeads(updateLeads);
  };

export const filterLeads = (leads) => {

  let validLeads = [];
  let invalidEmails = [];

  _.forEach(leads, (lead) => {
    if (
      !_.isNull(lead.email) &&
      !_.isEmpty(lead.email) &&
      emailRegex.test(lead.email)
    ) {
      validLeads.push(lead);
    } else {
      invalidEmails.push(lead.email);
    }
  });

  return {
    validLeads,
    invalidEmails
  };
};
