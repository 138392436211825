import React, { useState } from "react";
import { TextField, Button, Box, Typography, CircularProgress, Paper } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

const SpamComplaintReductionRateCalculator = () => {
  const [results, setResults] = useState("");

  const validationSchema = () => {
    let schema = Yup.object({
      emailsent: Yup.number()
        .typeError("Please enter a valid number")
        .required("Emails Sent is required")
        .min(1, "Please enter a number greater than or equal to 1"),

      currentSpamComplaintRate: Yup.number()
        .typeError("Please enter a valid number")
        .required("current Spam Complaint Rate is required")
        .min(1, "Please enter a number greater than or equal to 1"),
    });

    return schema;
  };

  const handleDataSubmit = async (values, helpers) => {
    try {
      const result = (values.emailsent * values.currentSpamComplaintRate) / 100;

      setResults(Math.floor(result * 0.3));
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message });
    }
  };

  const reSetData = () => {
    formik.resetForm();
    setResults("");
  };

  const formik = useFormik({
    initialValues: {
      emailsent: "",
      currentSpamComplaintRate: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDataSubmit(values, helpers, true);
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message });
      }
    },
  });

  return (
    <Box sx={{ px: { sm: "7%" }, py: { sm: 5 }, p: 4, fontFamily: "Inter" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
        Spam Complaint Rate Reduction Calculator
      </Typography>

      <Typography color="primary.main" sx={{ fontSize: "0.8rem" }}>
        Your email spam complaint rate is the rate at which subscribers report your email content as
        spam.
      </Typography>

      {/* Spam Complaint Rate Reduction Calculator Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            flexDirection: { xs: "column", sm: "row" },
            mt: { xs: "24px", sx: "24px", md: 3, lg: 3, xl: 3 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: { sm: 1 },
              flexDirection: { xs: "column", sm: "row" },
              mb: { xs: 0, sm: 0 },
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <TextField
              variant="outlined"
              onChange={formik.handleChange}
              error={!!(formik.touched.emailsent && formik.errors.emailsent)}
              helperText={formik.touched.emailsent && formik.errors.emailsent}
              onBlur={formik.handleBlur}
              name="emailsent"
              placeholder="No. of emails sent"
              value={formik?.values?.emailsent}
              type="number"
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { md: "21vw", sm: "35vw", xs: "auto" },
                  maxHeight: "44px",
                  color: "primary.main",
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: "8px", sm: "11px" },
                paddingRight: { sm: "0px" },
              }}
            />

            <TextField
              variant="outlined"
              onChange={formik.handleChange}
              error={
                !!(
                  formik.touched.currentSpamComplaintRate && formik.errors.currentSpamComplaintRate
                )
              }
              helperText={
                formik.touched.currentSpamComplaintRate && formik.errors.currentSpamComplaintRate
              }
              onBlur={formik.handleBlur}
              name="currentSpamComplaintRate"
              placeholder="Current spam complaint rate (%)"
              type="number"
              value={formik?.values?.currentSpamComplaintRate}
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { md: "21vw", sm: "35vw", xs: "auto" },
                  maxHeight: "44px",
                  color: "primary.main",
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: "8px", sm: "11px" },
                paddingRight: { sm: "0px" },
              }}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: "fit-content", xs: "100%" },
              padding: "10px",
              marginLeft: { xs: "7px", sm: "4" },
              marginTop: { xs: 2, sm: 0 },
              maxHeight: "44px",
              cursor:
                formik?.values?.emailsent && formik?.values?.currentSpamComplaintRate
                  ? "pointer"
                  : "not-allowed",
            }}
            disabled={!(formik?.values?.emailsent && formik?.values?.currentSpamComplaintRate)}
            type="submit"
          >
            {formik.isSubmitting ? <CircularProgress size={20} color="inherit" /> : <>Calculate</>}
          </Button>
        </Box>
      </form>

      {results !== "" && (
        <Box
          sx={{
            py: { sm: "40px", xs: "20px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            border: "6px solid #ECEDF5",
            padding: { sm: "30px", xs: "15px" },
            marginTop: "32px",
            borderRadius: "8px",
            width: "fit-content",
          }}
        >
          <Typography variant="h5" component="h3" sx={{ fontWeight: "bold", marginBottom: "20px" }}>
            Spam Complaint Rate Result
          </Typography>
          <Paper
            variant="outlined"
            sx={{
              borderRadius: "8px",
              width: { md: "55%", xs: "100%" },
              paddingTop: "32px",
            }}
          >
            <Box sx={{ borderBottom: "1px solid", padding: { sm: "32px", xs: "16px" } }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Spam Rate is <span style={{ color: "#D54444" }}>Poor</span>
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: 2,
                }}
              >
                <Typography>
                  Your spam rate is very poor. Your emails risk being flagged and blocked. We highly
                  recommend you use Success ai email validation.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ backgroundColor: "#F9F9FC", padding: "16px 32px 32px 32px" }}>
              <Typography>Estimated reduced spam complaints: {results}</Typography>
            </Box>
          </Paper>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#6E58F1",
              "&:hover": {
                backgroundColor: "#7c68f1",
              },
              color: "white",
              px: 4,
              py: 1.2,
              mt: 4,
              width: { md: "20%", xs: "100%" },
              borderRadius: "8px",
              minHeight: "35px",
            }}
            onClick={reSetData}
          >
            Start Over
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SpamComplaintReductionRateCalculator;
