import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  useMediaQuery,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const AddOnsTable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // For mobile screens

  const rows = [
    { label: 'DFY (Done-for-you) Accounts Setup', value: '$3 per Gsuite Account + $12 per Domain' },
    { label: 'Email Verifier', value: '$10 per 2500' },
    { label: 'SMS', value: 'Coming Soon' },
    { label: 'Website Visitor Identification', value: 'Coming Soon' },
    { label: 'Connecting additional Linkedin accounts', value: '$20 per account' },
    { label: 'Connecting additional Whatsapp accounts', value: '$20 per account' },
  ];

  return (
    <Box sx={{ padding: '20px', width:"100%" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Add <span style={{ color: '#1A73E8' }}>– Ons</span>
      </Typography>

      <TableContainer component={Paper}>
        {isMobile ? (
          <Grid container spacing={2}>
            {rows.map((row, index) => (
              <Grid item xs={12} key={index}>
                <Paper elevation={3} sx={{ padding: '16px' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    {row.label}
                  </Typography>
                  <Typography variant="body1" color="textPrimary">
                    {row.value}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" color="textPrimary">
                    Add-On
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="h6" color="textPrimary">
                    Price
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="subtitle1">{row.label}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" color={row.value === 'Coming Soon' ? 'textSecondary' : 'textPrimary'}>
                      {row.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
};

export default AddOnsTable;
