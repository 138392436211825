import React, { useState } from "react";
import {
  Typography,
  TextField,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Button,
  Box,
  IconButton,
  Paper,
  Grid,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const DmarcRecordGeneratorPage = () => {
  const [domainDetails, setDomainDetails] = useState([]);

  const validationSchema = () => {
    let schema = Yup.object({
      domain: Yup.string()
        .max(255)
        .required("Domain is required")
        .matches(
          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
          "Please add a valid Domain address"
        ),
    });

    return schema;
  };

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      // Dmark Record
      const dmarkRecord = `v=DMARC1; p=${values.policyType}; sp=${values.sumDomainpolicyType}; ${values.aggregateEmail} ${values.forensicEmail} rf=${values.reportFormat}; pct=${values.Percentage}; ri=${values.reportingInterval};`;

      // dmark Record Using Bind Key
      const dmarkRecordUsingBindKey = `_dmarc.${values.domain}. 3600 IN TXT ${dmarkRecord}`;

      // dmark Record Using Tiny DNS
      const dmarkRecordUsingTinyDNS = `_dmarc.${values.domain}:${dmarkRecord}:3600`;

      setDomainDetails([
        {
          dmarkRecord: dmarkRecord,
          dmarkRecordUsingBindKey: dmarkRecordUsingBindKey,
          dmarkRecordUsingTinyDNS: dmarkRecordUsingTinyDNS,
        },
      ]);
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message });
    }
  };

  const formik = useFormik({
    initialValues: {
      domain: "",
      policyType: "none",
      sumDomainpolicyType: "none",
      aggregateEmail: "",
      forensicEmail: "",
      reportFormat: "afrf",
      reportingInterval: "86400",
      Percentage: "100",
      dkimIdentifierAlignment: "relaxed",
      spfIdentifierAlignment: "relaxed",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers, true);
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message });
      }
      formik.resetForm();
    },
  });

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // copy success
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Box sx={{ px: { sm: "7%" }, py: { sm: 5 }, p: 4, fontFamily: "Inter" }}>
      <Typography variant="h5" component="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
        DMARC Record Generator
      </Typography>

      <Typography color="primary.main" sx={{ fontSize: "0.8rem" }}>
        Create DMARC records with our free DMARC generator
      </Typography>

      {/* Dmarc Recored Generator Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        {/* <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={4} mt={4}>
          <TextField
            placeholder="Enter a domain name"
            variant="outlined"
            onChange={formik.handleChange}
            error={!!(formik.touched.domain && formik.errors.domain)}
            helpertext={formik.touched.domain && formik.errors.domain}
            onBlur={formik.handleBlur}
            name="domain"
            value={formik?.values?.domain}
            sx={{
              borderColor: "gray.300",
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
                width: { sm: "21vw", xs: "55vw" },
                maxHeight: "44px",
                color: "primary.main",
              },
            }}
          />
        </Box> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              placeholder="Enter a domain name"
              variant="outlined"
              onChange={formik.handleChange}
              error={!!(formik.touched.domain && formik.errors.domain)}
              helpertext={formik.touched.domain && formik.errors.domain}
              onBlur={formik.handleBlur}
              name="domain"
              value={formik?.values?.domain}
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { sm: "21vw", xs: "55vw" },
                  maxHeight: "44px",
                  color: "primary.main",
                  marginTop: "1rem",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel htmlFor="domain-policy-type" sx={{ pb: 1 }}>
                Domain policy type*
              </FormLabel>
              <Select
                onChange={formik.handleChange}
                error={!!(formik.touched.policyType && formik.errors.policyType)}
                helpertext={formik.touched.policyType && formik.errors.policyType}
                onBlur={formik.handleBlur}
                name="policyType"
                value={formik?.values?.policyType}
                style={{ color: "primary.main", maxHeight: "44px", width: "100%" }}
              >
                <MenuItem value="none" style={{ fontSize: "0.9rem" }}>
                  None
                </MenuItem>
                <MenuItem value="qurantine" style={{ fontSize: "0.9rem" }}>
                  Qurantine
                </MenuItem>
                <MenuItem value="reject" style={{ fontSize: "0.9rem" }}>
                  Reject
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel htmlFor="failedSpf" sx={{ pb: 1 }}>
                Sub-Domain Policy Type (optional)
              </FormLabel>
              <Select
                onChange={formik.handleChange}
                error={!!(formik.touched.policyType && formik.errors.policyType)}
                helpertext={formik.touched.policyType && formik.errors.policyType}
                onBlur={formik.handleBlur}
                name="policyType"
                value={formik?.values?.policyType}
                style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
              >
                <MenuItem value="none" style={{ fontSize: "0.9rem" }}>
                  None
                </MenuItem>
                <MenuItem value="qurantine" style={{ fontSize: "0.9rem" }}>
                  Qurantine
                </MenuItem>
                <MenuItem value="reject" style={{ fontSize: "0.9rem" }}>
                  Reject
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <FormLabel component="legend" sx={{ pb: 1 }}>
                Aggregate Email (RUA) (optional)
              </FormLabel>
              <TextField
                variant="outlined"
                onChange={formik.handleChange}
                error={!!(formik.touched.aggregateEmail && formik.errors.aggregateEmail)}
                helpertext={formik.touched.aggregateEmail && formik.errors.aggregateEmail}
                onBlur={formik.handleBlur}
                name="aggregateEmail"
                value={formik?.values?.aggregateEmail}
                sx={{
                  borderColor: "gray.300",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    // width: { sm: "21vw", xs: "55vw" },
                    maxHeight: "44px",
                    color: "primary.main",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <FormLabel component="legend" sx={{ pb: 1 }}>
                Forensic Email (RUF) (optional)
              </FormLabel>
              <TextField
                variant="outlined"
                onChange={formik.handleChange}
                error={!!(formik.touched.forensicEmail && formik.errors.forensicEmail)}
                helpertext={formik.touched.forensicEmail && formik.errors.forensicEmail}
                onBlur={formik.handleBlur}
                name="forensicEmail"
                value={formik?.values?.forensicEmail}
                sx={{
                  borderColor: "gray.300",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    // width: { sm: "21vw", xs: "55vw" },
                    maxHeight: "44px",
                    color: "primary.main",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {" "}
            <FormControl sx={{ width: "100%" }}>
              <FormLabel htmlFor="report-format" sx={{ mb: 1 }}>
                Report Format (optional)
              </FormLabel>
              <Select
                onChange={formik.handleChange}
                error={!!(formik.touched.reportFormat && formik.errors.reportFormat)}
                helpertext={formik.touched.reportFormat && formik.errors.reportFormat}
                onBlur={formik.handleBlur}
                name="reportFormat"
                value={formik?.values?.reportFormat}
                style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
              >
                <MenuItem value="afrf" style={{ fontSize: "0.9rem" }}>
                  afrf
                </MenuItem>
                <MenuItem value="iodef" style={{ fontSize: "0.9rem" }}>
                  iodef
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {" "}
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <FormLabel component="legend" sx={{ pb: 1 }}>
                Reporting Interval (seconds)
              </FormLabel>
              <TextField
                variant="outlined"
                onChange={formik.handleChange}
                error={!!(formik.touched.reportingInterval && formik.errors.reportingInterval)}
                helpertext={formik.touched.reportingInterval && formik.errors.reportingInterval}
                onBlur={formik.handleBlur}
                name="reportingInterval"
                value={formik?.values?.reportingInterval}
                sx={{
                  borderColor: "gray.300",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    // width: { sm: "21vw", xs: "55vw" },
                    maxHeight: "44px",
                    color: "primary.main",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <FormLabel component="legend" sx={{ pb: 1 }}>
                Percentage (optional)
              </FormLabel>
              <TextField
                placeholder="Enter Percentage (Optional)"
                variant="outlined"
                onChange={formik.handleChange}
                error={!!(formik.touched.Percentage && formik.errors.Percentage)}
                helpertext={formik.touched.Percentage && formik.errors.Percentage}
                onBlur={formik.handleBlur}
                name="Percentage"
                value={formik?.values?.Percentage}
                sx={{
                  borderColor: "gray.300",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    // width: { sm: "21vw", xs: "55vw" },
                    maxHeight: "44px",
                    color: "primary.main",
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel htmlFor="dkim-identifier-allignment" sx={{ mb: 1 }}>
                DKIM Identifier Alignment (optional)
              </FormLabel>
              <Select
                onChange={formik.handleChange}
                error={
                  !!(
                    formik.touched.dkimIdentifierAlignment && formik.errors.dkimIdentifierAlignment
                  )
                }
                helpertext={
                  formik.touched.dkimIdentifierAlignment && formik.errors.dkimIdentifierAlignment
                }
                onBlur={formik.handleBlur}
                name="dkimIdentifierAlignment"
                value={formik?.values?.dkimIdentifierAlignment}
                style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
              >
                <MenuItem value="relaxed" style={{ fontSize: "0.9rem" }}>
                  Relaxed
                </MenuItem>
                <MenuItem value="strict" style={{ fontSize: "0.9rem" }}>
                  Strict
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel htmlFor="spf-identifier-allignment" sx={{ mb: 1 }}>
                SPF Identifier Alignment (optional)
              </FormLabel>
              <Select
                onChange={formik.handleChange}
                error={
                  !!(formik.touched.spfIdentifierAlignment && formik.errors.spfIdentifierAlignment)
                }
                helpertext={
                  formik.touched.spfIdentifierAlignment && formik.errors.spfIdentifierAlignment
                }
                onBlur={formik.handleBlur}
                name="spfIdentifierAlignment"
                value={formik?.values?.spfIdentifierAlignment}
                style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
              >
                <MenuItem value="relaxed" style={{ fontSize: "0.9rem" }}>
                  Relaxed
                </MenuItem>
                <MenuItem value="strict" style={{ fontSize: "0.9rem" }}>
                  Strict
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={3} mt={4}>
          <FormControl>
            <FormLabel htmlFor="domain-policy-type" sx={{ pb: 1 }} >
              Domain policy type*
            </FormLabel>
            <Select
              onChange={formik.handleChange}
              error={!!(formik.touched.policyType && formik.errors.policyType)}
              helpertext={formik.touched.policyType && formik.errors.policyType}
              onBlur={formik.handleBlur}
              name="policyType"
              value={formik?.values?.policyType}
              style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
            >
              <MenuItem value="none" style={{ fontSize: "0.9rem" }}>
                None
              </MenuItem>
              <MenuItem value="qurantine" style={{ fontSize: "0.9rem" }}>
                Qurantine
              </MenuItem>
              <MenuItem value="reject" style={{ fontSize: "0.9rem" }}>
                Reject
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="failedSpf" sx={{ pb: 1 }} >
              Sub-Domain Policy Type (optional)
            </FormLabel>
            <Select
              onChange={formik.handleChange}
              error={!!(formik.touched.policyType && formik.errors.policyType)}
              helpertext={formik.touched.policyType && formik.errors.policyType}
              onBlur={formik.handleBlur}
              name="policyType"
              value={formik?.values?.policyType}
              style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
            >
              <MenuItem value="none" style={{ fontSize: "0.9rem" }}>
                None
              </MenuItem>
              <MenuItem value="qurantine" style={{ fontSize: "0.9rem" }}>
                Qurantine
              </MenuItem>
              <MenuItem value="reject" style={{ fontSize: "0.9rem" }}>
                Reject
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl component="fieldset"  >
            <FormLabel component="legend" sx={{ pb: 1 }}>
              Aggregate Email (RUA) (optional)
            </FormLabel>
            <TextField
              variant="outlined"
              onChange={formik.handleChange}
              error={!!(formik.touched.aggregateEmail && formik.errors.aggregateEmail)}
              helpertext={formik.touched.aggregateEmail && formik.errors.aggregateEmail}
              onBlur={formik.handleBlur}
              name="aggregateEmail"
              value={formik?.values?.aggregateEmail}
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { sm: "21vw", xs: "55vw" },
                  maxHeight: "44px",
                  color: "primary.main",
                },
              }}
            />
          </FormControl>
        </Box> */}

        {/* <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={3} mt={4}>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ pb: 1 }}>
              Forensic Email (RUF) (optional)
            </FormLabel>
            <TextField
              variant="outlined"
              onChange={formik.handleChange}
              error={!!(formik.touched.forensicEmail && formik.errors.forensicEmail)}
              helpertext={formik.touched.forensicEmail && formik.errors.forensicEmail}
              onBlur={formik.handleBlur}
              name="forensicEmail"
              value={formik?.values?.forensicEmail}
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { sm: "21vw", xs: "55vw" },
                  maxHeight: "44px",
                  color: "primary.main",
                },
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="report-format" sx={{ mb: 1 }}>
              Report Format (optional)
            </FormLabel>
            <Select
              onChange={formik.handleChange}
              error={!!(formik.touched.reportFormat && formik.errors.reportFormat)}
              helpertext={formik.touched.reportFormat && formik.errors.reportFormat}
              onBlur={formik.handleBlur}
              name="reportFormat"
              value={formik?.values?.reportFormat}
              style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
            >
              <MenuItem value="afrf" style={{ fontSize: "0.9rem" }}>
                afrf
              </MenuItem>
              <MenuItem value="iodef" style={{ fontSize: "0.9rem" }}>
                iodef
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ pb: 1 }}>
              Reporting Interval (seconds)
            </FormLabel>
            <TextField
              variant="outlined"
              onChange={formik.handleChange}
              error={!!(formik.touched.reportingInterval && formik.errors.reportingInterval)}
              helpertext={formik.touched.reportingInterval && formik.errors.reportingInterval}
              onBlur={formik.handleBlur}
              name="reportingInterval"
              value={formik?.values?.reportingInterval}
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { sm: "21vw", xs: "55vw" },
                  maxHeight: "44px",
                  color: "primary.main",
                },
              }}
            />
          </FormControl>
        </Box> */}

        {/* <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} gap={3} mt={4}>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend" sx={{ pb: 1 }}>
              Percentage (optional)
            </FormLabel>
            <TextField
              placeholder="Enter Percentage (Optional)"
              variant="outlined"
              onChange={formik.handleChange}
              error={!!(formik.touched.Percentage && formik.errors.Percentage)}
              helpertext={formik.touched.Percentage && formik.errors.Percentage}
              onBlur={formik.handleBlur}
              name="Percentage"
              value={formik?.values?.Percentage}
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { sm: "21vw", xs: "55vw" },
                  maxHeight: "44px",
                  color: "primary.main",
                },
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="dkim-identifier-allignment" sx={{ mb: 1 }}>
              DKIM Identifier Alignment (optional)
            </FormLabel>
            <Select
              onChange={formik.handleChange}
              error={
                !!(formik.touched.dkimIdentifierAlignment && formik.errors.dkimIdentifierAlignment)
              }
              helpertext={
                formik.touched.dkimIdentifierAlignment && formik.errors.dkimIdentifierAlignment
              }
              onBlur={formik.handleBlur}
              name="dkimIdentifierAlignment"
              value={formik?.values?.dkimIdentifierAlignment}
              style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
            >
              <MenuItem value="relaxed" style={{ fontSize: "0.9rem" }}>
                Relaxed
              </MenuItem>
              <MenuItem value="strict" style={{ fontSize: "0.9rem" }}>
                Strict
              </MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="spf-identifier-allignment" sx={{ mb: 1 }}>
              SPF Identifier Alignment (optional)
            </FormLabel>
            <Select
              onChange={formik.handleChange}
              error={
                !!(formik.touched.spfIdentifierAlignment && formik.errors.spfIdentifierAlignment)
              }
              helpertext={
                formik.touched.spfIdentifierAlignment && formik.errors.spfIdentifierAlignment
              }
              onBlur={formik.handleBlur}
              name="spfIdentifierAlignment"
              value={formik?.values?.spfIdentifierAlignment}
              style={{ color: "primary.main", maxHeight: "44px", minWidth: "21vw" }}
            >
              <MenuItem value="relaxed" style={{ fontSize: "0.9rem" }}>
                Relaxed
              </MenuItem>
              <MenuItem value="strict" style={{ fontSize: "0.9rem" }}>
                Strict
              </MenuItem>
            </Select>
          </FormControl>
        </Box> */}

        <Box mt={4} sx={{ display: "flex", alignItems: "end", mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{
              px: 4,
              width: { sm: "fit-content" },
              maxHeight: "44px",
              cursor: formik?.values?.domain ? "pointer" : "not-allowed",
            }}
          >
            Generate
          </Button>
        </Box>
      </form>

      {/* result Table */}
      {domainDetails.length !== 0 && (
        <Box
          mt={8}
          p={2}
          sx={{ border: "2px solid #ECEDF5", borderRadius: "8px", maxWidth: { sm: "85vw" } }}
        >
          {/* DMARC Record  Section */}
          <Box className="public-key-container">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                variant="h6"
                color="text.primary"
                fontWeight="medium"
                sx={{ fontSize: "0.9rem" }}
              >
                DMARC Record
              </Typography>
              <IconButton
                sx={{ color: "#6E58F1" }}
                aria-label="copy public key"
                onClick={() => handleCopy(domainDetails[0].dmarkRecord)}
              >
                <ContentCopyIcon />
                <Typography component="span" ml={1} sx={{ fontSize: "0.9rem" }}>
                  Copy
                </Typography>
              </IconButton>
            </Box>
            <Paper variant="outlined" sx={{ backgroundColor: "#f9fafb", p: 2, mt: 2 }}>
              <Typography
                variant="body2"
                component="pre"
                color="text.secondary"
                style={{
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                }}
              >
                {domainDetails[0].dmarkRecord}
              </Typography>
            </Paper>
          </Box>

          {/* DMARC Record using Bind Section */}
          <Box className="private-key-container" mt={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                variant="h6"
                color="text.primary"
                fontWeight="medium"
                sx={{ fontSize: "0.9rem" }}
              >
                DMARC Record using Bind
              </Typography>
              <IconButton
                sx={{ color: "#6E58F1" }}
                aria-label="copy private key"
                onClick={() => handleCopy(domainDetails[0].dmarkRecordUsingBindKey)}
              >
                <ContentCopyIcon />
                <Typography component="span" ml={1} sx={{ fontSize: "0.9rem" }}>
                  Copy
                </Typography>
              </IconButton>
            </Box>
            <Paper variant="outlined" sx={{ backgroundColor: "#f9fafb", p: 2, mt: 2 }}>
              <Typography
                variant="body2"
                component="pre"
                color="text.secondary"
                style={{
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                }}
              >
                {domainDetails[0].dmarkRecordUsingBindKey}
              </Typography>
            </Paper>
          </Box>

          {/* DMARC Record using TinyDNS Section */}
          <Box className="private-key-container" mt={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography
                variant="h6"
                color="text.primary"
                fontWeight="medium"
                sx={{ fontSize: "0.9rem" }}
              >
                DMARC Record using TinyDNS
              </Typography>
              <IconButton
                sx={{ color: "#6E58F1" }}
                aria-label="copy private key"
                onClick={() => handleCopy(domainDetails[0].dmarkRecordUsingTinyDNS)}
              >
                <ContentCopyIcon />
                <Typography component="span" ml={1} sx={{ fontSize: "0.9rem" }}>
                  Copy
                </Typography>
              </IconButton>
            </Box>
            <Paper variant="outlined" sx={{ backgroundColor: "#f9fafb", p: 2, mt: 2 }}>
              <Typography
                variant="body2"
                component="pre"
                color="text.secondary"
                style={{
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                }}
              >
                {domainDetails[0].dmarkRecordUsingTinyDNS}
              </Typography>
            </Paper>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default DmarcRecordGeneratorPage;
