import React, { useState } from "react";
import { Box, Typography, TextField, Button, Grid, Paper, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEmailVerificationMutation } from "src/services/tool-service.js";

const EmailVerificationPage = () => {
  const [emailDetails, setEmailDetails] = useState([]);
  const [emailVerification] = useEmailVerificationMutation();

  const validationSchema = () => {
    return Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required")
        .test("no-plus", "Please add a valid email address", (value) => {
          return !value || !value.includes("+");
        }),
    });
  };

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      const result = await emailVerification(values).unwrap();
      if (result.message === "Fetched Error") {
        setEmailDetails([
          { error: "something Went Wroung Please Try Again", data: "", email: values.email },
        ]);
      } else {
        setEmailDetails([{ email: values.email, data: result.emailCheckerResponse, error: "" }]);
      }
    } catch (err) {
      console.log("the error => ", err);
      helpers.setErrors({ submit: err.data.error.message });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers);
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message });
      }
      formik.resetForm();
    },
  });

  return (
    <Box sx={{ px: { sm: "7%" }, py: { sm: 5 }, p: 4, fontFamily: "Inter" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
        EMAIL VERIFICATION TOOL
      </Typography>
      <Typography color="primary.main" sx={{ fontSize: "0.8rem" }}>
        Insert the email address of the contact you want verified to get an instant score.
      </Typography>

      {/* Email Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            flexDirection: { xs: "column", sm: "row" },
            mt: { xs: "24px", sx: "24px", md: 3, lg: 3, xl: 3 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: { sm: 1 },
              flexDirection: { xs: "column", sm: "row" },
              mb: { xs: 0, sm: 0 },
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Enter a Email address"
              onChange={formik.handleChange}
              error={!!(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              name="email"
              value={formik?.values?.email}
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { md: "21vw", sm: "35vw", xs: "auto" },
                  maxHeight: "44px",
                  color: "primary.main",
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: "8px", sm: "11px" },
                paddingRight: { sm: "0px" },
              }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: "fit-content", xs: "100%" },
              padding: "10px",
              marginLeft: { xs: "7px", sm: "4" },
              marginTop: { xs: 2, sm: 0 },
              maxHeight: "44px",
              cursor: formik?.values?.email ? "pointer" : "not-allowed",
            }}
            disabled={!formik?.values?.email}
            type="submit"
          >
            {formik.isSubmitting ? <CircularProgress size={20} color="inherit" /> : <>Look up</>}
          </Button>
        </Box>

        {formik.errors.submit && (
          <Box sx={{ display: "flex", alignItems: "flex-end", mt: 3 }}>
            <Typography color="error" sx={{ mt: 3, textAlign: "right" }} variant="body2">
              {formik.errors.submit}
            </Typography>
          </Box>
        )}
      </form>

      {/* Result Table */}
      {emailDetails.length !== 0 && emailDetails[0].error === "" && (
        <Box
          sx={{
            py: { xs: "20px", sm: "40px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
            border: "6px solid #ECEDF5",
            p: { xs: "15px", sm: "30px" },
            mt: 8,
            borderRadius: "8px",
            width: "fit-content",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", fontSize: "20px" }}>
            Email Information
          </Typography>

          <Box
            sx={{
              border: "1px solid #ECEDF5",
              width: { xs: "100%", sm: "55%" },
              my: "20px",
            }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              py: "20px",
              p: { xs: "20px", sm: "30px" },
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", m: 2 }}>
              {emailDetails[0]?.data?.isValidData ? (
                <CheckCircleIcon sx={{ color: "#34B24E" }} />
              ) : (
                <CancelIcon sx={{ color: "#FF0000" }} />
              )}
            </Box>
            <Box sx={{ ml: "10px" }}>
              <Typography variant="h6" sx={{ fontSize: "24px", fontWeight: "bold" }}>
                {emailDetails[0].email}
              </Typography>
              <Typography sx={{ mt: "8px" }}>
                {emailDetails[0]?.data?.isValidData
                  ? "This email address is safe and will receive emails"
                  : "This email address is not safe and will not receive emails"}
              </Typography>
            </Box>
          </Box>

          <Paper sx={{ p: { xs: "15px", sm: "40px" }, backgroundColor: "#F9F9FC" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ fontSize: "20px" }}>
                  Format{" "}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "12px",
                      p: "5px",
                      borderRadius: "5px",
                      ml: "10px",
                      color:
                        emailDetails[0]?.data?.isValidEmail?.status === "valid"
                          ? "#198754"
                          : "#D54444",
                      backgroundColor:
                        emailDetails[0]?.data?.isValidEmail?.status === "valid"
                          ? "#D1FFBD"
                          : "#F2C5C5",
                    }}
                  >
                    {emailDetails[0]?.data?.isValidEmail?.status === "valid" ? "Valid" : "Invalid"}
                  </Typography>
                </Typography>
                <Typography>
                  {emailDetails[0].isValidEmail?.status === "valid"
                    ? "This email address has the correct format and is not gibberish."
                    : "This email address has not the correct format and it is looks like gibberish."}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="h6" sx={{ fontSize: "20px" }}>
                  Disposable{" "}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "12px",
                      p: "5px",
                      borderRadius: "5px",
                      ml: "10px",
                      color:
                        emailDetails[0]?.data?.isDisposiableEmail?.status === "valid"
                          ? "#198754"
                          : "#D54444",
                      backgroundColor:
                        emailDetails[0]?.data?.isDisposiableEmail?.status === "valid"
                          ? "#D1FFBD"
                          : "#F2C5C5",
                    }}
                  >
                    {emailDetails[0]?.data?.isDisposiableEmail?.status === "valid"
                      ? "Valid"
                      : "Invalid"}
                  </Typography>
                </Typography>
                <Typography>
                  {emailDetails[0]?.data?.isDisposiableEmail?.status
                    ? "The Provided E-mail Doesn't Have Disposable Domain."
                    : "Email is from a known disposable domain"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ mt: "30px" }}>
                <Typography variant="h6" sx={{ fontSize: "20px" }}>
                  Server status{" "}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "12px",
                      p: "5px",
                      borderRadius: "5px",
                      ml: "10px",
                      color:
                        emailDetails[0]?.data?.serverStatus?.status === "valid"
                          ? "#198754"
                          : "#D54444",
                      backgroundColor:
                        emailDetails[0]?.data?.serverStatus?.status === "valid"
                          ? "#D1FFBD"
                          : "#F2C5C5",
                    }}
                  >
                    {emailDetails[0]?.data?.serverStatus?.status === "valid" ? "Valid" : "Invalid"}
                  </Typography>
                </Typography>
                <Typography>
                  {emailDetails[0]?.data?.serverStatus?.status === "valid"
                    ? "MX Records are present for the domain and we can connect to the SMTP server these MX records point to."
                    : "MX Records are Not present for the domain and we can not connect to the SMTP server these MX records point to."}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} sx={{ mt: "30px" }}>
                <Typography variant="h6" sx={{ fontSize: "20px" }}>
                  Email Status{" "}
                  <Typography
                    component="span"
                    sx={{
                      fontSize: "12px",
                      p: "5px",
                      borderRadius: "5px",
                      ml: "10px",
                      color:
                        emailDetails[0]?.data?.emailStatus?.status === "valid"
                          ? "#198754"
                          : "#D54444",
                      backgroundColor:
                        emailDetails[0]?.data?.emailStatus?.status === "valid"
                          ? "#D1FFBD"
                          : "#F2C5C5",
                    }}
                  >
                    {emailDetails[0]?.data?.emailStatus?.status === "valid" ? "Valid" : "Invalid"}
                  </Typography>
                </Typography>
                <Typography>
                  {emailDetails[0]?.data?.emailStatus?.status === "valid"
                    ? "This email address exists and can receive emails."
                    : "This email address doesn't exists and cannot receive emails."}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default EmailVerificationPage;
