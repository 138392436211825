import {
  ArrowDropDown,
  EmailTwoTone,
  Fireplace,
  FolderSpecial,
  InsertEmoticon,
  MoveToInbox,
} from "@mui/icons-material";
import { Box, Divider, FormControlLabel, Grid, Switch, Typography, Checkbox, Tooltip, Slider, TextField, Button, CircularProgress, } from "@mui/material";
import { EmailDrawerDisabledHero } from "src/assets/EmailDrawerDisabledHero";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import {
  usePauseWarmupMutation,
  useEnableWarmupMutation,
  accountUpdated,
} from "src/services/account-service.js";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { getWeekdays, getDayName, last7dates } from "src/utils/util.js";
import { styled } from "@mui/material/styles";
import { EDSuperbHero } from "src/assets/emailAccounts/emailDrawer/EDSuperbHero";
import { OnCheckboxCustomIcon } from "src/assets/general/OnCheckboxCustomIcon.js";
import { EDWarmupEmailsSent } from "src/assets/emailAccounts/emailDrawer/EDWarmupEmailsSent";
import { EDEmailsReceived } from "src/assets/emailAccounts/emailDrawer/EDEmailsReceived";
import { EDLandedInbox } from "src/assets/emailAccounts/emailDrawer/EDLandedInbox";
import { EDSavedSpam } from "src/assets/emailAccounts/emailDrawer/EDSavedSpam";
import { EDStar } from "src/assets/emailAccounts/emailDrawer/EDStar";
import { useGetMeQuery } from "src/services/user-service";
import { EDSMeterIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSMeterIcon.js";
import { useUpdateIntercomMutation } from "src/services/intercom-service";
import { EDSGrowthIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSGrowthIcon.js";
import { EDSMessagingIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSMessagingIcon.js";
import { EDSCalendarIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCalendarIcon.js";
import { EDSFIleCheckIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSFIleCheckIcon.js";
import { EDSChartIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSChartIcon.js";
import { EDSMailOpenIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSMailOpenIcon.js";
import { EDSWarningIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSWarningIcon.js";
import { EDSStarIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSStarIcon.js";
import { EDSCancelIcon } from "src/assets/emailAccounts/emailDrawer/EDSettingsTab/EDSCancelIcon.js";
import CustomCheckbox from "../CustomCheckbox.js";
import { OffCheckboxCustomIcon } from "src/assets/general/OffCheckboxCustomIcon.js";
import {
  usePauseAccountMutation,
  useResumeAccountMutation,
  useUpdateAccountMutation,
  useTestSmtpImapMutation
} from "src/services/account-service.js";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const WarmupTab = ({ account, canEdit, bulkUpdate, accountIds }) => {
  const dispatch = useDispatch();
  const last7Days = account.warmupStats?.last7Days ? account.warmupStats?.last7Days : {};
  const spamSeries = [];
  const InboxSeries = [];
  for (const iterator of last7dates().reverse()) {
    const stats = last7Days[iterator];
    const spamCount = stats?.spam_count ? stats.spam_count : 0;
    const inboxCount = stats?.inbox_count ? stats.inbox_count : 0;
    if (stats) {
      spamSeries.push({ x: getDayName(iterator), y: spamCount });
      InboxSeries.push({ x: getDayName(iterator), y: inboxCount });
    } else {
      spamSeries.push({ x: getDayName(iterator), y: spamCount });
      InboxSeries.push({ x: getDayName(iterator), y: inboxCount });
    }
  }
  const series = [
    {
      name: "Emails Landed in Spam",
      group: "spam",
      data: spamSeries,
    },
    {
      name: "Landed in inbox",
      group: "sent",
      data: InboxSeries,
    },
  ];

  const [options] = useState({
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      // toolbar: {
      //   show: true,
      // },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        // dataLabels: {
        //   total: {
        //     enabled: true,
        //     style: {
        //       fontSize: "13px",
        //       fontWeight: 900,
        //     },
        //   },
        // },
      },
    },
    xaxis: {
      type: "day",
      categories: getWeekdays(),
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    colors: ["#EB755A", "#82ED92"],
  });

  const [warmupStatus, setWarmupStatus] = useState(account.warmup.status);
  const [enableWarmup] = useEnableWarmupMutation();
  const [pauseWarmup] = usePauseWarmupMutation();
  const { data: user, refetch: refetchUser } = useGetMeQuery();
  const [updateIntercom] = useUpdateIntercomMutation();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isHighLimitOkay, setIsHighLimitOkay] = useState(false);
  const [highDailyLimitDialogOpen, setHighDailyLimitDialogOpen] = useState(false);
  const [isBulkUpdating, setIsBulkUpdating] = useState(false);
  const [updateAccount, { isLoading: isAccountUpdating, data }] = useUpdateAccountMutation();
  const [domainNameError, setDomainNameError] = useState(false);
  const [showMoreAdvSettings, setShowMoreAdvSettings] = useState(true);
  const [signature, setSignature] = useState("");
  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  useEffect(() => {
    setWarmupStatus(account.warmup.status);
  }, [account]);

  const handleEnableWarmup = async () => {
    if (account.status === "paused") {
      toast.error("Please resume your account first.");
      return;
    }
    else {
      const toastId = toast.loading("Loading...", { duration: Infinity });
      const { message, account: updatedAccount } = await enableWarmup(account._id).unwrap();
      window.Intercom('trackEvent', "Email account warmup initiated");
      dispatch(accountUpdated(updatedAccount));
      await updateIntercom({ user: user._id, attribute: "warmedup_email_accounts" })
      setWarmupStatus("enabled");
      toast.success(message, { id: toastId, duration: 2000 });
    }
  };

  const handlePauseWarmup = async () => {
    const toastId = toast.loading("Loading...", { duration: Infinity });
    const { message, account: updatedAccount } = await pauseWarmup(account._id).unwrap();
    dispatch(accountUpdated(updatedAccount));
    await updateIntercom({ user: user._id, attribute: "warmedup_email_accounts" })
    setWarmupStatus("paused");
    toast.success(message, { id: toastId, duration: 2000 });
  };

  const formik = useFormik({
    initialValues: {
      name: {
        first: account?.name?.first,
        last: account?.name?.last,
      },
      campaign: {
        dailyLimit: account?.campaign?.dailyLimit,
        waitTime: account?.campaign?.waitTime,
      },
      replyTo: account?.replyTo,
      signature: account?.signature,
      customDomain: {
        isEnable: account?.customDomain?.isEnable,
        name: account?.customDomain?.name,
      },
      warmup: {
        basicSetting: {
          increasePerDay: account?.warmup?.basicSetting?.increasePerDay,
          slowWarmupDisabled: account?.warmup?.basicSetting?.slowWarmupDisabled,
          limitPerDay: account?.warmup?.basicSetting?.limitPerDay,
          replyRate: account?.warmup?.basicSetting?.replyRate,
          alertBlock: account?.warmup?.basicSetting?.alertBlock,
        },
        advanceSetting: {
          weekdayOnly: account?.warmup?.advanceSetting?.weekdayOnly,
          readEmulation: account?.warmup?.advanceSetting?.readEmulation,
          customTrackingDomain: account?.warmup?.advanceSetting?.customTrackingDomain,
          openRate: account?.warmup?.advanceSetting?.openRate,
          spamProtectionRate: account?.warmup?.advanceSetting?.spamProtectionRate,
          markImportantRate: account?.warmup?.advanceSetting?.markImportantRate,
        },
      },
    },
    validationSchema: Yup.object({
      name: Yup.object({
        first: Yup.string(),
        last: Yup.string(),
      }),
      campaign: Yup.object({
        dailyLimit: Yup.number().integer("Must be an integer"),
        // .required("Daily Limit is required"),
        waitTime: Yup.number().integer("Must be an integer")
        // .required("Daily Limit is required"),
      }),

      replyTo: Yup.string().email().label("Reply to"),

      warmup: Yup.object({
        basicSetting: Yup.object({
          increasePerDay: Yup.number().integer().min(1).max(4),
          // .required().label("Increase per day"),
          limitPerDay: Yup.number().integer().min(1).max(50),
          // .required().label("Daily warmup limit"),
          replyRate: Yup.number().integer().min(1).max(100)
          // .required().label("Reply rate"),
        }),
      }),
    }),
    onSubmit: async (values) => {
      if (
        account?.campaign?.dailyLimit !== values.campaign.dailyLimit &&
        values.campaign.dailyLimit > 300 &&
        !isHighLimitOkay
      ) {
        setHighDailyLimitDialogOpen(true);
        return;
      }
      setHighDailyLimitDialogOpen(false);
      setIsHighLimitOkay(false);
      try {
        if (bulkUpdate) {
          setIsBulkUpdating(true);
          for (const accountId of accountIds) {
            const { account } = await updateAccount({ id: accountId, data: values }).unwrap();
            dispatch(accountUpdated(account));
          }
          setIsBulkUpdating(false);
          toast.success("Accounts Updated!");
        } else {
          if (values.customDomain.isEnable && values.customDomain.name === "") {
            setDomainNameError(true);
          } else {
            const { message, account: updatedAccount } = await updateAccount({
              id: account._id,
              data: { ...values, signature },
            }).unwrap();
            dispatch(accountUpdated(updatedAccount));
            toast.success(message);
            setDomainNameError(false);
          }
        }
      } catch (error) {
        setIsBulkUpdating(false);
        toast.error(error.data.error.message);
      }
    },
  });
  const handleIncreasePerDayChange = (event) => {
    const { value } = event.target;
    if (value > 4) {
      event.target.value = 4;
      toast.error("Max allowed value is 4");
    }
    formik.handleChange(event);
  };

  const handleDailyWarmupLimitChange = (event) => {
    const { value } = event.target;
    if (value > 50) {
      event.target.value = 20;
      toast.error("Max allowed value is 50");
    }
    formik.handleChange(event);
  };
  return (
    <>
      {warmupStatus !== "disabled" && !bulkUpdate && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {" "}
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              lineHeight: "16px",
              color: "#28287B",
            }}
          >
            Enable warmup for this account to check its performance
          </Typography>
          {canEdit && (
            <FormControlLabel
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              sx={{ m: 0 }}
              control={
                <Tooltip
                  title={account.warmup.warmupDisable ? "Your Warmup is currently inactive." : "Resume your account"}
                  placement="top" onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  sx={{ textAlign: "center" }}
                  arrow
                  open={isTooltipOpen && (account.status === "paused" || account.warmup.warmupDisable)}
                >
                  <span>
                    <IOSSwitch
                      sx={{}}
                      // name={name}
                      checked={warmupStatus === "enabled" && true}
                      disabled={account.warmup.warmupDisable === true || warmupStatus === "disabled"}
                      onChange={() => {
                        warmupStatus === "enabled" ? handlePauseWarmup() : handleEnableWarmup();
                      }}
                    />
                  </span>
                </Tooltip>
              }
            />
          )}

          {/* <Box
            sx={{
              width: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "40px",
              borderRadius: 1,
              border: "1px solid gray",
            }}
          >
            <Box
              sx={{
                width: "100px",
                height: "100%",
                backgroundColor: warmupStatus === "enabled" ? "white" : "#465571",
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handlePauseWarmup}
            >
              <Typography
                sx={{ fontWeight: "600", color: warmupStatus === "enabled" ? "#aaaaaa" : "white" }}
              >
                Disable
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100px",
                height: "100%",
                backgroundColor: warmupStatus === "enabled" ? "#216fed" : "white",
                borderTopRightRadius: 5,
                borderBottomRightRadius: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleEnableWarmup}
            >
              <Typography
                sx={{ fontWeight: "600", color: warmupStatus === "enabled" ? "white" : "#aaaaaa" }}
              >
                Enable
              </Typography>
            </Box>
          </Box> */}
        </Box>
      )}

      {warmupStatus !== "enabled" && !bulkUpdate ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            mt: 2,
            visibility: "hidden",
          }}
        >
          <Typography sx={{ fontSize: "18px", mb: 4 }}>
            Enable warmup for this account to check its performance
          </Typography>
          <EmailDrawerDisabledHero />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
            <Grid container spacing={2} sx={{ mt: 2, mb: 5 }}>
              {!bulkUpdate && (
                <>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        borderRadius: "12px",
                        p: 3,
                        border: "1px solid #E4E4E5",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        gap: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "13px",
                          fontWeight: 400,
                          lineHeight: "16px",
                          color: "#28287B",
                        }}
                      >
                        Warmup deliverability - past week
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",

                          width: "100%",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: "fit-content",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* <EDSuperbHero /> */}
                          <EDStar />
                        </Box>
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "20px",
                              fontWeight: 700,
                              lineHeight: "25px",
                              color: "#28287B",
                            }}
                          >
                            {account.warmupStats?.health_score >= 75 && account.warmupStats?.health_score <= 100 ? "Excellent  Results!" :
                              account.warmupStats?.health_score >= 50 && account.warmupStats?.health_score <= 75 ? " Good  Results!" :
                                account.warmupStats?.health_score >= 25 && account.warmupStats?.health_score <= 50 ? "Medium  Results!" :
                                  "Poor  Results!"
                            }
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#8181B0",
                              mt: 1,
                            }}
                          >
                            {account.warmupStats?.health_score || 0}% of your warmup emails successfully
                            landed in the inbox.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        width: "100%",
                        borderRadius: "12px",
                        p: 3,
                        border: "1px solid #E4E4E5",
                        height: "100%",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "13px", fontWeight: 400, lineHeight: "16px", color: "#28287B" }}
                      >
                        Summary - last week
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "20px",
                            color: "#28287B",
                            display: "flex",
                            alignItems: "center",
                            // mx: 2,
                            justifyContent: "flex-start",
                            // width: "5ch",
                            width: { xs: "100%", sm: "25%" },
                            mt: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              mr: 1,
                              gap: 2,
                            }}
                          >
                            <EDWarmupEmailsSent />
                          </Box>
                          <Box>
                            <Typography sx={{ color: "#8181B0", fontWeight: "400", fontSize: "13px" }}>
                              {" "}
                              Warmup Emails Sent
                            </Typography>
                            <Typography sx={{ color: "#28287B", fontWeight: "700", fontSize: "14px" }}>
                              {" "}
                              {account.warmupStats?.sent_count ?? 0}
                            </Typography>
                          </Box>
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "20px",
                            color: "#28287B",
                            display: "flex",
                            alignItems: "center",
                            // mx: 2,
                            justifyContent: "flex-start",
                            // width: "5ch",
                            mt: 2,
                            width: { xs: "100%", sm: "25%" },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1,
                            }}
                          >
                            <EDLandedInbox />
                          </Box>

                          <Box>
                            <Typography sx={{ color: "#8181B0", fontWeight: "400", fontSize: "13px" }}>
                              {" "}
                              Landed in Inbox
                            </Typography>
                            <Typography sx={{ color: "#28287B", fontWeight: "700", fontSize: "14px" }}>
                              {" "}
                              {account.warmupStats?.inbox_count ?? 0}{" "}
                            </Typography>
                          </Box>
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "20px",
                            color: "#28287B",
                            display: "flex",
                            justifyContent: "flex-start",
                            // width: "5ch",
                            alignItems: "center",
                            // mx: 2,
                            mt: 2,
                            width: { xs: "100%", sm: "25%" },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1,
                            }}
                          >
                            <EDSavedSpam />
                          </Box>

                          <Box>
                            <Typography sx={{ color: "#8181B0", fontWeight: "400", fontSize: "13px" }}>
                              {" "}
                              Saved from spam
                            </Typography>
                            <Typography sx={{ color: "#28287B", fontWeight: "700", fontSize: "14px" }}>
                              {" "}
                              {account.warmupStats?.spam_count ?? 0}{" "}
                            </Typography>
                          </Box>
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: 700,
                            lineHeight: "20px",
                            color: "#28287B",
                            display: "flex",
                            justifyContent: "flex-start",
                            // width: "5ch",
                            alignItems: "center",
                            // mx: 2,
                            mt: 2,
                            width: { xs: "100%", sm: "25%" },
                            // mb: 1.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1,
                            }}
                          >
                            <EDEmailsReceived />
                          </Box>
                          <Box>
                            <Typography sx={{ color: "#8181B0", fontWeight: "400", fontSize: "13px" }}>
                              {" "}
                              Warmup Emails Received
                            </Typography>
                            <Typography sx={{ color: "#28287B", fontWeight: "700", fontSize: "14px" }}>
                              {" "}
                              {account.warmupStats?.received_count ?? 0}{" "}
                            </Typography>
                          </Box>
                        </Typography>
                      </Box>
                      {/* <Divider sx={{ my: 2 }} /> */}
                    </Box>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Box sx={{ borderRadius: "12px", p: 3, mt: !bulkUpdate ? 3 : 0, border: "1px solid #E4E4E5" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ mb: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                          borderBottom: "1px solid #E4E4E5",
                          pb: 2,
                        }}
                      >
                        {/* <Whatshot sx={{ color: "rgb(33, 111, 237)", mr: 1 }} /> */}
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20px",
                            color: "#28287B",
                          }}
                        >
                          Warmup Settings | Basics
                        </Typography>
                      </Box>
                      {!bulkUpdate && (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            borderBottom: "1px solid #E4E4E5",
                            mt: 2,
                            pb: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              lineHeight: "26px",
                              color: "#28287B",
                            }}
                          >
                            Warmup filter tag:
                          </Typography>
                          <Typography
                            sx={{
                              ml: 1,
                              backgroundColor: "#F2F4F6",
                              p: 1,
                              borderRadius: 1.5,
                              fontSize: "13px",
                              fontWeight: 500,
                              lineHeight: "16px",
                              color: "#28287B",
                              border: "1px solid #E4E4E5",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(account.warmup.filterTag);
                              toast.success("Code copied!");
                            }}
                          >
                            {account.warmup.filterTag}
                          </Typography>
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        rowGap: 1,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "26px",
                            color: "#28287B",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mr: 1.5,
                            }}
                          >
                            <EDSGrowthIcon />
                          </Box>
                          Increase per day
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1.5,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#8181B0",
                          }}
                        >
                          Suggested 1, Max 4
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={formik.values.warmup.basicSetting.slowWarmupDisabled}
                              name="warmup.basicSetting.slowWarmupDisabled"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              icon={<OffCheckboxCustomIcon />}
                              checkedIcon={<OnCheckboxCustomIcon />}
                            />
                          }
                          label="Disable Slow Warmup"
                          sx={{
                            mt: 1,
                            display: { xs: "none", sm: "block" },
                            "& .MuiFormControlLabel-label": {
                              fontSize: "13px",
                              fontWeight: 500,
                              lineHeight: "16px",
                              color: "#28287B",
                              ml: 1,
                            },
                          }}
                        />{" "}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          sx={{
                            display: formik.values.warmup.basicSetting.slowWarmupDisabled && "none",
                            width: 80,
                            height: 40,
                            backgroundColor: "white",
                            "& div": { pl: 0.3 },
                            "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                            "& div input": {
                              py: 1.3,
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "16px",
                              letterSpacing: "0em",
                              "&::placeholder": {
                                color: "rgba(40, 40, 123, 0.5)",
                              },
                            },
                            mr: 1.5,
                          }}
                          name="warmup.basicSetting.increasePerDay"
                          value={formik.values.warmup.basicSetting.increasePerDay}
                          onChange={handleIncreasePerDayChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.warmup?.basicSetting?.increasePerDay &&
                            !!formik.errors.warmup?.basicSetting?.increasePerDay
                          }
                          helperText={
                            formik.touched.warmup?.basicSetting?.increasePerDay &&
                            formik.errors.warmup?.basicSetting?.increasePerDay
                          }
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                        />
                      </Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.warmup.basicSetting.slowWarmupDisabled}
                            name="warmup.basicSetting.slowWarmupDisabled"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            icon={<OffCheckboxCustomIcon />}
                            checkedIcon={<OnCheckboxCustomIcon />}
                          />
                        }
                        label="Disable Slow Warmup"
                        sx={{
                          mt: 1,
                          display: { xs: "block", sm: "none" },
                          "& .MuiFormControlLabel-label": {
                            fontSize: "13px",
                            fontWeight: 500,
                            lineHeight: "16px",
                            color: "#28287B",
                            ml: 1,
                          },
                        }}
                      />{" "}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        rowGap: 1,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "26px",
                            color: "#28287B",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}
                          >
                            <EDSMeterIcon />
                          </Box>
                          Daily Warmup Limit
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1.5,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#8181B0",
                          }}
                        >
                          Suggested 20, Max 50
                        </Typography>
                        {/* <FormControlLabel
                  control={<Checkbox />}
                  label="No Slow Warmup"
                  sx={{ fontSize: "14px", visibility: "hidden" }}
                /> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          name="warmup.basicSetting.limitPerDay"
                          value={formik.values.warmup.basicSetting.limitPerDay}
                          // onChange={(event) => {
                          //   const inputValue = event.target.value;
                          //   if (inputValue <= 200 && /^\d*$/.test(inputValue)) {
                          //     formik.setFieldValue("warmupSetting.limitPerDay", inputValue);
                          //     inputValue > 50 &&
                          //       toast("Warmup limit is too high. It's recommended to keep it below 50.", {
                          //         duration: 4000,
                          //         position: "top-center",
                          //         style: { color: "orange" },
                          //       });
                          //   } else if (inputValue > 200) {
                          //     formik.setFieldValue("warmupSetting.limitPerDay", 200);
                          //     toast.error("Max allowed value is 200");
                          //   }
                          // }}
                          onChange={handleDailyWarmupLimitChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.warmup?.basicSetting?.limitPerDay &&
                            !!formik.errors.warmup?.basicSetting?.limitPerDay
                          }
                          helperText={
                            formik.touched.warmup?.basicSetting?.limitPerDay &&
                            formik.errors.warmup?.basicSetting?.limitPerDay
                          }
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          sx={{
                            width: 80,
                            height: 40,
                            backgroundColor: "white",
                            "& div": { pl: 0.3 },
                            "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                            "& div input": {
                              py: 1.3,
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "16px",
                              letterSpacing: "0em",
                              "&::placeholder": {
                                color: "rgba(40, 40, 123, 0.5)",
                              },
                            },
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        rowGap: 1,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        // mt: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "26px",
                            color: "#28287B",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}
                          >
                            <EDSMessagingIcon />
                          </Box>
                          Reply rate %
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1.5,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#8181B0",
                          }}
                        >
                          Suggested 30
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          mt: 1,
                        }}
                      >
                        <TextField
                          variant="outlined"
                          size="small"
                          name="warmup.basicSetting.replyRate"
                          value={formik.values.warmup.basicSetting.replyRate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.warmup?.basicSetting?.replyRate &&
                            !!formik.errors.warmup?.basicSetting?.replyRate
                          }
                          helperText={
                            formik.touched.warmup?.basicSetting?.replyRate &&
                            formik.errors.warmup?.basicSetting?.replyRate
                          }
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          sx={{
                            width: 80,
                            height: 40,
                            backgroundColor: "white",
                            "& div": { pl: 0.3 },
                            "& div fieldset": { borderRadius: "8px", border: "1px solid #E4E4E5" },
                            "& div input": {
                              py: 1.3,
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "16px",
                              letterSpacing: "0em",
                              "&::placeholder": {
                                color: "rgba(40, 40, 123, 0.5)",
                              },
                            },
                            mr: 1.5,
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        rowGap: 1,
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        // mt: 5,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            fontWeight: 700,
                            lineHeight: "12px",
                            color: "#28287B",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", justifyContent: "center", alignItems: "center", mr: 1 }}
                          >
                            <EDSCancelIcon />
                          </Box>
                          Monitor Blacklists
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1.5,
                            fontSize: "13px",
                            fontWeight: 400,
                            lineHeight: "20px",
                            color: "#8181B0",
                          }}
                        >
                          Alert when blocked
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          mr: "-26px",
                        }}
                      >
                        <CustomCheckbox
                          name="warmup.basicSetting.alertBlock"
                          checked={formik.values.warmup.basicSetting.alertBlock}
                          onChange={formik.handleChange}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ borderRadius: "12px", p: 3, pb: 0, mt: 2, border: "1px solid #E4E4E5", mb: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{}}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          width: "100%",
                          borderBottom: "1px solid #E4E4E5",
                          pb: 2,
                        }}
                      >
                        {/* <Whatshot sx={{ color: "#ffb600", mr: 1 }} /> */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontWeight: 700,
                              lineHeight: "20px",
                              color: "#28287B",
                            }}
                          >
                            Warmup Settings | Advanced
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#8181B0",
                              mt: 1,
                            }}
                          >
                            Advanced settings to make warmup behavior more human-like
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mt: 4,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "75%",
                            mr: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <EDSCalendarIcon />
                            </Box>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "26px",
                                color: "#28287B",
                                ml: 1.5,
                              }}
                            >
                              Weekdays Only
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              mt: 1,
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#8181B0",
                            }}
                          >
                            Only send warmup emails on weekdays for a more natural sending pattern
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mr: "-26px",
                          }}
                        >
                          <CustomCheckbox
                            name="warmup.advanceSetting.weekdayOnly"
                            checked={formik.values.warmup.advanceSetting.weekdayOnly}
                            onChange={formik.handleChange}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          my: 4,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "75%",
                            mr: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <EDSFIleCheckIcon />
                            </Box>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "26px",
                                color: "#28287B",
                                ml: 1.5,
                              }}
                            >
                              Read Emulation
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              mt: 1,
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#8181B0",
                            }}
                          >
                            Spend time and scroll through your warmup email to emulate human-like reading
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mr: "-26px",
                          }}
                        >
                          <CustomCheckbox
                            name="warmup.advanceSetting.readEmulation"
                            checked={formik.values.warmup.advanceSetting.readEmulation}
                            onChange={formik.handleChange}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: "75%",
                            mr: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <EDSChartIcon />
                            </Box>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "14px",
                                fontWeight: 700,
                                lineHeight: "26px",
                                color: "#28287B",
                                ml: 1.5,
                              }}
                            >
                              Warm custom tracking domain
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              mt: 1,
                              fontSize: "13px",
                              fontWeight: 400,
                              lineHeight: "20px",
                              color: "#8181B0",
                            }}
                          >
                            Include your custom tracking domain in your warmup emails to further improve
                            deliverability
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            mr: "-26px",
                          }}
                        >
                          <CustomCheckbox
                            name="warmup.advanceSetting.customTrackingDomain"
                            checked={formik.values.warmup.advanceSetting.customTrackingDomain}
                            onChange={formik.handleChange}
                          />
                        </Box>
                      </Box>
                      <Button
                        sx={{
                          display: "none",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          fontSize: "14px",
                          color: "#216fed",
                          my: 2,
                        }}
                        onClick={() => {
                          setShowMoreAdvSettings(!showMoreAdvSettings);
                        }}
                      >
                        {" "}
                        Show {showMoreAdvSettings ? "less" : "more"}
                        <ArrowDropDown
                          sx={{
                            transform: showMoreAdvSettings && "rotate(-180deg)",
                            transition: "0.2s all ease-in-out",
                          }}
                        />
                      </Button>

                      <Box
                        sx={{
                          display: showMoreAdvSettings ? "flex" : "none",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          width: "100%",
                          flexDirection: "column",
                          mt: 3,
                        }}
                      >
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 4,
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "45%",
                              mr: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <EDSMailOpenIcon />
                              </Box>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "26px",
                                  color: "#28287B",
                                  ml: 1.5,
                                }}
                              >
                                Open rate
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                mt: 1,
                                fontSize: "13px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                color: "#8181B0",
                              }}
                            >
                              How many of your warm up emails to open
                            </Typography>
                          </Box>
                          <Box sx={{ width: "25%" }}>
                            {" "}
                            <Slider
                              aria-label="Always visible"
                              name="warmup.advanceSetting.openRate"
                              value={formik.values.warmup.advanceSetting.openRate}
                              onChange={formik.handleChange}
                              valueLabelDisplay="on"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 4,
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "45%",
                              mr: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <EDSWarningIcon />
                              </Box>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "26px",
                                  color: "#28287B",
                                  ml: 1.5,
                                }}
                              >
                                Spam Protection
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                mt: 1,
                                fontSize: "13px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                color: "#8181B0",
                              }}
                            >
                              How many of your warm up emails to save from spam folder
                            </Typography>
                          </Box>
                          <Box sx={{ width: "25%" }}>
                            {" "}
                            <Slider
                              aria-label="Always visible"
                              name="warmup.advanceSetting.spamProtectionRate"
                              value={formik.values.warmup.advanceSetting.spamProtectionRate}
                              onChange={formik.handleChange}
                              valueLabelDisplay="on"
                            />
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mb: 4,
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              width: "45%",
                              mr: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <EDSStarIcon />
                              </Box>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: 700,
                                  lineHeight: "26px",
                                  color: "#28287B",
                                  ml: 1.5,
                                }}
                              >
                                Mark important
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                mt: 1,
                                fontSize: "13px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                color: "#8181B0",
                              }}
                            >
                              How many of your warm up emails to mark as important
                            </Typography>
                          </Box>
                          <Box sx={{ width: "25%" }}>
                            {" "}
                            <Slider
                              aria-label="Always visible"
                              name="warmup.advanceSetting.markImportantRate"
                              value={formik.values.warmup.advanceSetting.markImportantRate}
                              onChange={formik.handleChange}
                              valueLabelDisplay="on"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {!bulkUpdate && (
                <Grid item xs={12}>
                  {" "}
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "12px",
                      p: 3,
                      border: "1px solid #E4E4E5",
                      height: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        color: "#28287B",
                      }}
                    >
                      Summary of Warmup Emails Sent
                    </Typography>
                    <ReactApexChart options={options} series={series} type="bar" height={280} />
                  </Box>
                </Grid>
              )}
            </Grid>
            <SaveButton
              onClick={formik.handleSubmit}
              isSubmitting={bulkUpdate ? isBulkUpdating : isAccountUpdating}
              canEdit={canEdit}
            />
          </Box>
        </>
      )}
    </>
  );
};
const SaveButton = ({ onClick, isSubmitting, canEdit }) => {
  return (
    <>
      {canEdit && (
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "18px",
            py: { xs: 1, sm: 2 },
            px: 3,
          }}
          variant="contained"
          type="submit"
          onClick={onClick}
        >
          {isSubmitting ? (
            <>
              <CircularProgress color="inherit" size={20} thickness={5} sx={{ mr: 1 }} />
              Saving
            </>
          ) : (
            <>Save</>
          )}
        </Button>
      )}
    </>
  );
};
export default WarmupTab;
