import {
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  LinearProgress,
  CircularProgress,
  IconButton,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DownloadOutlined, Close, ArrowDropDown } from "@mui/icons-material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import {
  useGetCurrentPlanQuery,
  useUpdatePlanMutation,
  useBillingUsageQuery,
  useGetPaymentMethodQuery,
  useUpdatePaymentMutation,
  useInvoicesQuery,
} from "src/services/billing-service.js";
import { config } from "src/config.js";
import { toast } from "react-hot-toast";
import { Total } from "src/assets/campaignDetailsLeads/Total";
import { CheckCircleIcon } from "src/assets/general/CheckCircleIcon";
import { useGetLeadsUsageQuery, useGetSavedFilesQuery } from "src/services/leads-service";
import { downloadCsv } from "src/utils/util";
import moment from "moment";
import { useGetMeQuery } from "src/services/user-service";

import { useGetAccountAnalyticsMutation } from "src/services/campaign-service";
import PlanCard from "./PlanCard";
import React from 'react';
import PopoverMenu from "./PopoverMenu";
import RemoveWorkSpaceDialog from "./RemoveWorkspaceDialog";
import { setWorkspace, useDeleteWorkSpaceMutation, useGetWorkSpacesMutation, useUpdateWorkSpaceStatusMutation } from "src/services/workSpace-service";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useAuth } from "src/auth/auth";
import AddOnsTable from "./AddOnsTable";
const UpdatePlanDialog = (props) => {
  const { open, handleClose } = props;
  return (
    <>
      {" "}
      <Dialog open={open} onClose={handleClose} sx={{ backgroundColor: "rgba(4, 4, 30, 0.5)" }}>
        <DialogTitle>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 700,
              lineHeight: "30px",
              color: "#28287B",
            }}
          >
            Great decision!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              lineHeight: "28px",
              color: "#8181B0",
            }}
          >
            {" "}
            Are you ready to scale your campaigns and purchase this plan?
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 3 }}>
            {" "}
            <Button onClick={handleClose} variant="contained" fullWidth>
              Yes Purchase!!
            </Button>
            <Button onClick={handleClose} variant="outlined" fullWidth sx={{ ml: 2 }}>
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

const Features = ({ type, plan, typePlan }) => {
  const generateWarmupFeature = (plan) => {
    let activeLeads, emails, AIcredits, support, qualityLeads, mailBox, emailSending;
    switch (plan) {
      case "growth":
        activeLeads = "6,000";
        emails = "6,000";
        AIcredits = "10,000";
        support = "Live Chat";
        qualityLeads = "1,200";
        mailBox = "5";
        emailSending = "Unlimited Email Sending";
        break;  
      case "skyrocket":
        activeLeads = "200,000";
        emails = "200,000";
        AIcredits = "20,000";
        support = "Premium";
        qualityLeads = "5,000";
        mailBox = "100";
        emailSending = "Buyer Intent Data";
        break;  
      case "enterprise":
        activeLeads = "500,000";
        emails = "500,000";
        AIcredits = "30,000";
        support = "Premium";
        qualityLeads = "Unlimited";
        mailBox = "250";
        emailSending = "Everything in GROWTH+";
        break;
      default:
        activeLeads = "50";
        emails = "100";
        AIcredits = "50";
        support = "Live Chat";
        qualityLeads = "100";
        emailSending = "100";
        break;
    }
  
    // Build common features based on the plan
    const commonFeatures = [];
  
    // Features for all plans
    commonFeatures.push(
      { icon: <CheckCircleIcon color="#ffffff" />, text: "Unlimited Emails & Phone Number Lookups" },
      { icon: <CheckCircleIcon color="#ffffff" />, text: `${qualityLeads} Email Exports` },
      { icon: <CheckCircleIcon color="#ffffff" />, text: `${qualityLeads} Phone Number Exports` },
    );
  if (plan === "skyrocket") {
    commonFeatures.push(
      { text: "Everything in Starter +", isHeading: true }
    );
  } else if (plan === "enterprise") {
    commonFeatures.push(
      { text: "Everything in Growth+", isHeading: true }
    );
  }

    // Conditional features for the growth plan
    if (plan === "growth") {
      commonFeatures.push(
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Unlimited Email Sending" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Unlimited Email Warmup" },
      );
    }
  
    // Continue adding features for all plans
    commonFeatures.push(
      { icon: <CheckCircleIcon color="#00AA38" />, text: `${activeLeads} Active Contacts` },
      { icon: <CheckCircleIcon color="#00AA38" />, text: `Connect up to ${mailBox} Mailboxes` },
      { icon: <CheckCircleIcon color="#00AA38" />, text: `${AIcredits} AI Writer credits` },
    );
  
    // Additional features for the growth plan
    if (plan === "growth") {
      commonFeatures.push(
        { icon: <CheckCircleIcon color="#00AA38" />, text: "AI Auto-Reply *" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Unified Inbox - InboxHub" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: "CRM - Pipeline Management *" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Zapier Integration *" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: `${support} Support` }
      );
    }
  
    // Additional features for the skyrocket plan
    if (plan === "skyrocket") {
      commonFeatures.push(
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Buyer Intent Data" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Multichannel Sequences: email, LinkedIn, phone" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Workspaces" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Team Functions (Team Invites & Roles)" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: "Webhooks & API Access" },
        { icon: <CheckCircleIcon color="#00AA38" />, text: `${support} Chat Support` }
      );
    }
    return commonFeatures;
  };
  const generateLeadsFeature = (plan) => {
    let qualityLeads, support;
    switch (plan) {
      case "skyrocket":
        qualityLeads = "1,200";
        support = "Live Chat";
        break;
      case "growth":
        qualityLeads = "5,000";
        support = "Premium";
        break;
      case "10xscale":
        qualityLeads = "10,000";
        support = "Premium";
        break;
      default:
        qualityLeads = "30";
        support = "Live Chat";
        break;
    }
    return [
      { icon: <CheckCircleIcon color="#00AA38" />, text: `Each Month: ${qualityLeads} Quality Leads` },
      { icon: <CheckCircleIcon color="#00AA38" />, text: "Pay For Verified Leads Only" },
      { icon: <CheckCircleIcon color="#00AA38" />, text: "Precise & Advanced Filter Tools" },
      { icon: <CheckCircleIcon color="#00AA38" />, text: "Data Enrichment" },
      { icon: <CheckCircleIcon color="#00AA38" />, text: `${support} Support` },
      { icon: <CheckCircleIcon color="#00AA38" />, text: "Lead Enrichment" },
    ];
  };
  const featureList = type === "warmup" ? generateWarmupFeature(plan) : generateLeadsFeature(plan);
  return (
    <Box>
      <Typography sx={{ fontWeight: "700", fontSize: "14px", color: "#28287B" }}>
        Includes
      </Typography>
      <Box>
        {featureList.map((feature, index) => (
          <Typography
            key={index}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "5px",
              py: 1,
            }}
          >
            {feature.icon}
            <Typography
              sx={{
                color: "#28287B",
                fontSize: "13px",
                fontWeight:
                  (index === 3 && (plan === "skyrocket" || plan === "enterprise"))
                    ? "700"
                    : "500",
              }}
            >
              {feature.text}
            </Typography>
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

const BillingAndUsage = (props) => {
  const { mode, fromCreateWorkspace, canEdit, canDelete } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [leadCreditDialogOpen, setLeadCreditDialogOpen] = useState(false);
  const [leadCreditTab, setLeadCreditTab] = useState("");
  const [billingMonthly, setBillingMonthly] = useState(false);
  const [isPlanUpdating, setIsPlanUpdating] = useState(false);
  const [UpdatePayment] = useUpdatePaymentMutation();
  const { data: fetchedCurrentPlan, refetch: refetchCurrentPlan } = useGetCurrentPlanQuery();
  const [currentPlan, setCurrentPlan] = useState(fetchedCurrentPlan);
  const { data: user } = useGetMeQuery();
  const [getWorkSpaces] = useGetWorkSpacesMutation();
  const [timelineButtons, setTimeLineButtons] = useState([]);
  const [timeline, setTimeline] = useState(null);
  const [deleteWorkSpace, {isLoading: isDeletingWorkspace}] = useDeleteWorkSpaceMutation();
  const [activeWorkspace, setActiveWorkspace] = useState({})

  useEffect(() => {
    if(fromCreateWorkspace){
      const timer = setTimeout(async () => {
        const docs = await getWorkSpaces().unwrap();
        setTimeLineButtons(docs?.uniqueWorkspaces);
        setActiveWorkspace(docs?.currentWorkspace)
      }, 500);
      return () => clearTimeout(timer);
  }
  }, [getWorkSpaces]);

  useEffect(() => {
    setTimeline(activeWorkspace);
    dispatch(setWorkspace(activeWorkspace));
    if (activeWorkspace) {
      localStorage.setItem("workspace", JSON.stringify(activeWorkspace));
    }
  }, [timelineButtons]);
  const { data, isLoading: isCardLoading } = useGetPaymentMethodQuery();
  let payment = data?.length > 0 ? data[0] : [];

  const [updatePlan] = useUpdatePlanMutation();

  const { data: usage, isLoading: isLoadingUsage, refetch: refetchUsage } = useBillingUsageQuery();

  const activeLeadsExceeded = Math.abs(usage?.usedActiveLeads) >= usage?.activeLeads;
  const emailCreditExceeded = Math.abs(usage?.usedEmailCredit) >= usage?.montlyEmailCredit;
  const aiCreditExceeded = Math.abs(usage?.usedAiCredit) >= usage?.aiWriterLimit;
  const dailyLeadLimitCount = Math.abs(usage?.dailyLeadLimit) >= usage?.dailyLeadLimit;
  const bounceCreditCount = Math.abs(usage?.bounceCredit) >= usage?.bounceCredit;
  const {
    data: invoices,
    refetch: refetchInvoice,
  } = useInvoicesQuery();
  const { data: leadUsage, refetch: refetchLeadUsage } = useGetLeadsUsageQuery();
  const { data: downloadFiles, refetch: refetchSavedFiles } = useGetSavedFilesQuery();
  useEffect(() => {
    refetchUsage();
    refetchInvoice();
    refetchLeadUsage();
    refetchSavedFiles();
  }, [refetchUsage, refetchInvoice, refetchSavedFiles, refetchLeadUsage]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (fromCreateWorkspace) {
      setCurrentPlan({});
    } else {
      setCurrentPlan(fetchedCurrentPlan);
    }
  }, [fromCreateWorkspace, fetchedCurrentPlan]);

  const [workspaceId, setWorkspaceId] = useState();
  const workspace = useSelector((state) => state.workspace);
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  },[workspace])

  const navigateToCreateWorkspace = () => {
    navigate("/new/workSpace/create", { state: { from: location.pathname } });
  }

  const handleUpdatePlanClick = async (priceId) => {
    if (isCurrentSendingWarmupPlan(priceId) || isCurrentLeadsPlan(priceId)) return;

    const toastId = toast.loading("Updating...", { duration: Infinity });
    setIsPlanUpdating(true);
    const session = await updatePlan({ priceId, workspaceId }).unwrap();
    if (session.url) {
      window.location.href = session.url;
    } else if (session.errorMsg) {
      toast.error(session.errorMsg, { id: toastId, duration: 5000 });
    } else {
      toast.success("Plan Updated", { id: toastId, duration: 2000 });
      await refetchCurrentPlan();
      refetchUsage();
    }
    setIsPlanUpdating(false);
  };

  const handelDowloadCsv = (item) => {
    downloadCsv(item.name, item.data);
  };

  const isCurrentSendingWarmupPlan = (priceId) => {
    return currentPlan?.subscription?.sendingWarmup?.planId === priceId;
  };

  const handelBillingCheckout = async () => {
    const { data: url } = await UpdatePayment();
    window.location.href = url;
  };

  const isCurrentLeadsPlan = (priceId) => {
    return currentPlan?.subscription?.leads?.planId === priceId;
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const scrollBarStyle = {
    // width
    "&::-webkit-scrollbar": {
      width: "14px",
    },

    // Track
    "&::-webkit-scrollbar-track": {
      borderRadius: "60px",
    },

    // /* Handle */
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E4E5",
      borderRadius: "10px",
      border: "4px solid rgba(0, 0, 0, 0)",
      backgroundClip: "padding-box",
    },

    // /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d5d5d5",
    },
  };


  const getUserPlan = (user) => {
    const plan = user?.EarlyBirdPlan?.replace('EarlyBird', '') ?? '';
    return `You Are Currently On ${plan} Plan.`;
  };

  const [getAccountAnalytics] = useGetAccountAnalyticsMutation();
  const fetchGraphData = useCallback(async () => {
    if (currentPlan?.subscription?.sendingWarmup?.expiresAt) {
      const expiresAtValueOne = new Date(currentPlan?.subscription?.sendingWarmup?.expiresAt); // Replace with your actual date
      const expiresAtDateNo = new Date(expiresAtValueOne);
      expiresAtDateNo.setMonth(expiresAtDateNo.getMonth() - 1);
    } else {
      const expiresAtValueFree = new Date(currentPlan?.freeTrialExpiresAt); // Replace with your actual date
      // const expiresAtValueFree = "2028-11-16T07:16:15.962+00:00";
      const expiresAtDateNoFree = new Date(expiresAtValueFree);
      expiresAtDateNoFree.setMonth(expiresAtDateNoFree.getMonth() - 1);
    }
  }, [getAccountAnalytics, currentPlan, workspaceId]);

  useEffect(() => {
    fetchGraphData();
  }, [fetchGraphData]);

  const credits = usage?.leadsCredits?.toLocaleString() || 0;
  const usedAiCredit = usage?.usedAiCredit?.toLocaleString() || 0;
  const aiWriterLimit = usage?.aiWriterLimit?.toLocaleString() || 0;
  const dailyLeadLimit = usage?.dailyLeadLimit?.toLocaleString() || 0;
  const bounceCredit = usage?.bounceCredit?.toLocaleString() || 0;
  const montlyEmailCredit = usage?.montlyEmailCredit?.toLocaleString() || 0;
  const usedActiveLeads = usage?.usedActiveLeads?.toLocaleString() || 0;
  const activeLeads = usage?.activeLeads?.toLocaleString() || 0;
  const emailSentCount = usage?.usedEmailCredit.toLocaleString() || 0;
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [openRemoveWorkspaceDialog, setOpenRemoveWorkspaceDialog] = useState(false);
  const [updateWorkSpaceStatus] = useUpdateWorkSpaceStatusMutation();
  const dispatch = useDispatch();


  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleRemoveWorkspaceClick = async () => {
    const { workSpace } = await deleteWorkSpace(timeline._id).unwrap();
    setTimeline(workSpace.defaultWorkspace);
    dispatch(setWorkspace(workSpace.defaultWorkspace));
    setOpenRemoveWorkspaceDialog(false);
    navigate("/accounts");
    refetchCurrentPlan();
  }
  const handleReload = () => {
    navigate(0);
  };

  const handleWorkspaceChange = async (newTimeline) => {
    const { data } = await updateWorkSpaceStatus({ workspaceId: newTimeline?._id });
    const updatedWorkspace = data?.updatedWorkspace;
    const currentPlan = data?.currentPlan;
    refetchCurrentPlan();
    if (currentPlan || updatedWorkspace?.appSumoCode) {
      navigate("/accounts");
      handleReload();
    } else {
      navigate("/workSpace/billing", { state: { fromCreateWorkspace: true }, replace: true });
    }
    setTimeline(updatedWorkspace?.data?.workSpace);
    dispatch(setWorkspace(updatedWorkspace?.data?.workSpace));
  };

  return (
    <>
      {fromCreateWorkspace && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
          <Box sx={{ display: 'flex', width: '100%', maxWidth: '800px' }}>
            <Button
              sx={{
                backgroundColor: '#007bff',
                color: 'white',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'bold',
                width: "40%",
                mb: 1,
                mt: 4,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                "&:hover": {
                  backgroundColor: "#007bff",
                },
              }}
              onClick={handleClick1}
            >
              {timeline?.name}
              <ArrowDropDown sx={{ fontSize: '32px', ml: 2 }} />
            </Button>

            <PopoverMenu
              anchorEl={anchorEl1}
              handleClose={handleClose1}
              timelineButtons={timelineButtons}
              setTimeline={handleWorkspaceChange}
              navigateToCreateWorkspace={navigateToCreateWorkspace}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '800px' }}>
            <Typography sx={{ mb: 1, fontWeight: 'bold', fontSize: '32px' }}>
              Let's get your new workspace ready
            </Typography>
            <Typography sx={{ color: '#6B7280', fontSize: '20px', mb: 3 }}>
              Upgrade to one of our plans to continue.
            </Typography>
          </Box>
        </Box>
      )}
      {!fromCreateWorkspace && workspace?.assignedPlan && workspace?.assignedPlan.length > 0 && !workspace?.isAppSumoRefund && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: "0px 12px 15px 0px #4B71970D",
            borderRadius: "12px",
            backgroundColor: "white",
            width: "100%",
            p: { xs: 1, sm: 2, md: 3 },
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              lineHeight: "26px",
              color: "#28287B",
              ml: 1.5,
            }}
          >
            You Are Currently On App Sumo {workspace?.assignedPlan} Plan.
          </Typography>
        </Box>
      )}

      {!fromCreateWorkspace && workspace?.isEarlyBirdAccessUser &&
        workspace?.EarlyBirdPlan.length > 0 &&
        !workspace?.isEarlyBirdDealRefund && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              boxShadow: "0px 12px 15px 0px #4B71970D",
              borderRadius: "12px",
              backgroundColor: "white",
              width: "100%",
              p: { xs: 1, sm: 2, md: 3 },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "26px",
                color: "#28287B",
                ml: 1.5,
              }}
            >
              {getUserPlan(workspace)}
            </Typography>
          </Box>
        )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: "0px 12px 15px 0px #4B71970D",
            borderRadius: "12px",
            backgroundColor: "white",
            width: "100%",
            p: { xs: 1, sm: 2, md: 3 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#F2F4F6",
                width: "100%",
                borderRadius: "8px",
                p: 0.4,
                border: "1px solid #F2F4F7",
              }}
            >
              <Grid item xs={6}>
                <Button
                  // variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor:
                      mode === "trial"
                        ? !billingMonthly
                          ? "#0071F6"
                          : "transparent"
                        : !billingMonthly
                          ? "white"
                          : "transparent",
                    color:
                      mode === "trial"
                        ? !billingMonthly
                          ? "#fff"
                          : "#28287B"
                        : !billingMonthly
                          ? "#0071F6"
                          : "#8181B0",
                    "&:hover": {
                      backgroundColor:
                        mode === "trial"
                          ? !billingMonthly
                            ? "#0071F6"
                            : "transparent"
                          : !billingMonthly
                            ? "white"
                            : "transparent",
                    },
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    letterSpacing: "0em",
                    boxShadow: !billingMonthly && "0px 1px 2px 0px #1018280F",
                    borderRadius: "5px",
                    // mr: 0.5,
                    py: 1,
                  }}
                  onClick={() => {
                    setBillingMonthly(false);
                  }}
                >
                  Annual Summer Offer (Save 40%)
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  // variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor:
                      mode === "trial"
                        ? billingMonthly
                          ? "#0071F6"
                          : "transparent"
                        : billingMonthly
                          ? "white"
                          : "transparent",
                    color:
                      mode === "trial"
                        ? billingMonthly
                          ? "#fff"
                          : "#28287B"
                        : billingMonthly
                          ? "#0071F6"
                          : "#8181B0",
                    "&:hover": {
                      backgroundColor:
                        mode === "trial"
                          ? billingMonthly
                            ? "#0071F6"
                            : "transparent"
                          : billingMonthly
                            ? "white"
                            : "transparent",
                    },
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    letterSpacing: "0em",
                    boxShadow: billingMonthly && "0px 1px 2px 0px #1018280F",
                    borderRadius: "5px",
                    // mr: 0.5,
                    py: 1,
                    height: "100%",
                  }}
                  onClick={() => {
                    setBillingMonthly(true);
                  }}
                >
                  Monthly Billing
                </Button>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px",
                border: "1px solid #E4E4E5",
                p: 3,
                mt: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    color: "#28287B",
                  }}
                >
                  Sending & Warmup
                </Typography> */}
              </Box>
              <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      border: "1px solid #3F4FF8",
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      // backgroundColor:"red"
                    }}
                  >
                    <PlanCard
                      title="Starter"
                      price={billingMonthly ? "59" : "35"}
                      billingMonthly={billingMonthly}
                      handleUpdatePlanClick={handleUpdatePlanClick}
                      isCurrentPlan={isCurrentSendingWarmupPlan(
                        billingMonthly
                          ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
                          : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
                      )}
                      planId={
                        billingMonthly
                          ? config.SENDING_WARMUP_MONTHLY_GROWTH_PRICE_ID
                          : config.SENDING_WARMUP_YEARLY_GROWTH_PRICE_ID
                      }
                      isPlanUpdating={isPlanUpdating}
                      discountPrice={billingMonthly ? "" : "59"}
                      testlines={<Features type="warmup" plan="growth" typePlan={billingMonthly} />}
                    />
                    {/* <Features type="warmup" plan="growth" typePlan={billingMonthly} /> */}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      border: "1px solid #3F4FF8",
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PlanCard
                      title="Growth"
                      price={billingMonthly ? "99" : "59"}
                      billingMonthly={billingMonthly}
                      handleUpdatePlanClick={handleUpdatePlanClick}
                      isCurrentPlan={isCurrentSendingWarmupPlan(
                        billingMonthly
                          ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
                          : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
                      )}
                      planId={
                        billingMonthly
                          ? config.SENDING_WARMUP_MONTHLY_SKYROCKET_PRICE_ID
                          : config.SENDING_WARMUP_YEARLY_SKYROCKET_PRICE_ID
                      }
                      isPlanUpdating={isPlanUpdating}
                      discountPrice={billingMonthly ? "" : "99"}
                      testlines={<Features type="warmup" plan="skyrocket" typePlan={billingMonthly} />}
                    />
                    {/* <Features type="warmup" plan="skyrocket" typePlan={billingMonthly} /> */}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      border: "1px solid #3F4FF8",
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PlanCard
                      title="Infinity"
                      price={billingMonthly ? "149" : "89"}
                      billingMonthly={billingMonthly}
                      handleUpdatePlanClick={handleUpdatePlanClick}
                      isCurrentPlan={isCurrentSendingWarmupPlan(
                        billingMonthly
                          ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
                          : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
                      )}
                      planId={
                        billingMonthly
                          ? config.SENDING_WARMUP_MONTHLY_SCALE_PRICE_ID
                          : config.SENDING_WARMUP_YEARLY_SCALE_PRICE_ID
                      }
                      isPlanUpdating={isPlanUpdating}
                      discountPrice={billingMonthly ? "" : "149"}
                      testlines={<Features type="warmup" plan="enterprise" typePlan={billingMonthly} />}
                    />
                    {/* <Features type="warmup" plan="enterprise" typePlan={billingMonthly} /> */}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px",
                border: "1px solid #E4E4E5",
                mt: 3,
                p: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    color: "#28287B",
                  }}
                >
                  Lead Finder
                </Typography>
              </Box>
              <Grid container columnSpacing={3} rowSpacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      border: "1px solid #3F4FF8",
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PlanCard
                      title="Growth Leads"
                      price={billingMonthly ? "44" : "22"}
                      billingMonthly={billingMonthly}
                      handleUpdatePlanClick={handleUpdatePlanClick}
                      isCurrentPlan={isCurrentLeadsPlan(
                        billingMonthly
                          ? config.LEADS_MONTHLY_SKYROCKET_PRICE_ID
                          : config.LEADS_YEARLY_SKYROCKET_PRICE_ID
                      )}
                      planId={
                        billingMonthly
                          ? config.LEADS_MONTHLY_SKYROCKET_PRICE_ID
                          : config.LEADS_YEARLY_SKYROCKET_PRICE_ID
                      }
                      isPlanUpdating={isPlanUpdating}
                    />
                    <Features type="leads" plan="skyrocket" typePlan={billingMonthly}  />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      border: "1px solid #3F4FF8",
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PlanCard
                      title="Skyrocket Leads"
                      price={billingMonthly ? "111" : "55.5"}
                      billingMonthly={billingMonthly}
                      handleUpdatePlanClick={handleUpdatePlanClick}
                      isCurrentPlan={isCurrentLeadsPlan(
                        billingMonthly
                          ? config.LEADS_MONTHLY_GROWTH_PRICE_ID
                          : config.LEADS_YEARLY_GROWTH_PRICE_ID
                      )}
                      planId={
                        billingMonthly
                          ? config.LEADS_MONTHLY_GROWTH_PRICE_ID
                          : config.LEADS_YEARLY_GROWTH_PRICE_ID
                      }
                      isPlanUpdating={isPlanUpdating}
                    />
                    <Features type="leads" plan="growth" typePlan={billingMonthly}  />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "8px",
                      border: "1px solid #3F4FF8",
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <PlanCard
                      title="10X Scale Leads"
                      price={billingMonthly ? "194" : "97"}
                      billingMonthly={billingMonthly}
                      handleUpdatePlanClick={handleUpdatePlanClick}
                      isCurrentPlan={isCurrentLeadsPlan(
                        billingMonthly
                          ? config.LEADS_MONTHLY_SCALE_PRICE_ID
                          : config.LEADS_YEARLY_SCALE_PRICE_ID
                      )}
                      planId={
                        billingMonthly
                          ? config.LEADS_MONTHLY_SCALE_PRICE_ID
                          : config.LEADS_YEARLY_SCALE_PRICE_ID
                      }
                      isPlanUpdating={isPlanUpdating}
                    />
                    <Features type="leads" plan="10xscale" typePlan={billingMonthly} />
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            boxShadow: "0px 12px 15px 0px #4B71970D",
            borderRadius: "12px",
            backgroundColor: "white",
            width: "100%",
            mt: 3,
            border: activeLeadsExceeded ? "1px solid red" : "none",
          }}
        >
          <AddOnsTable />
        </Box>
        {!fromCreateWorkspace && mode !== "trial" && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: "0px 12px 15px 0px #4B71970D",
                borderRadius: "12px",
                backgroundColor: "white",
                width: "100%",
                p: 3,
                mt: 3,
                border: activeLeadsExceeded ? "1px solid red" : "none",
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  color: "#28287B",
                }}
              >
                Active Contacts
              </Typography>
              {isLoadingUsage ? (
                <CircularProgress size={25} thickness={5} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "#28287B",
                    }}
                  >
                    {activeLeadsExceeded ? activeLeads : usedActiveLeads}/{activeLeads}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "red",
                      display: activeLeadsExceeded ? "block" : "none",
                    }}
                  >
                    (You have exceeded the limit for uploading contacts)
                  </Typography>
                </Box>
              )}
              <Box sx={{ width: "100%", mt: 2 }}>
                {" "}
                <LinearProgress
                  variant="determinate"
                  value={((usage?.usedActiveLeads || 0) / usage?.activeLeads) * 100}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: "0px 12px 15px 0px #4B71970D",
                borderRadius: "12px",
                backgroundColor: "white",
                width: "100%",
                p: 3,
                mt: 3,
                border: emailCreditExceeded ? "1px solid red" : "none",
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  color: "#28287B",
                }}
              >
                Emails Per Month
              </Typography>
              {isLoadingUsage ? (
                <CircularProgress size={25} thickness={5} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "#28287B",
                    }}
                  >
                    {emailCreditExceeded ? montlyEmailCredit : emailSentCount}/{montlyEmailCredit}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "red",
                      display: emailCreditExceeded ? "block" : "none",
                    }}
                  >
                    (You have used up all of your email credits)
                  </Typography>
                </Box>
              )}
              <Box sx={{ width: "100%", mt: 2 }}>
                {" "}
                <LinearProgress
                  variant="determinate"
                  value={(usage?.usedEmailCredit / usage?.montlyEmailCredit) * 100}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: "0px 12px 15px 0px #4B71970D",
                borderRadius: "12px",
                backgroundColor: "white",
                width: "100%",
                p: 3,
                mt: 3,
                border: aiCreditExceeded ? "1px solid red" : "none",
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  color: "#28287B",
                }}
              >
                AI Writer Credits
              </Typography>
              {isLoadingUsage ? (
                <CircularProgress size={25} thickness={5} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "#28287B",
                    }}
                  >
                    {aiCreditExceeded ? aiWriterLimit : usedAiCredit}/{aiWriterLimit}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "red",
                      display: aiCreditExceeded ? "block" : "none",
                    }}
                  >
                    (You have used up all of your AI writer credits)
                  </Typography>
                </Box>
              )}
              <Box sx={{ width: "100%", mt: 2 }}>
                {" "}
                <LinearProgress
                  variant="determinate"
                  value={(usage?.usedAiCredit / usage?.aiWriterLimit) * 100}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: "0px 12px 15px 0px #4B71970D",
                borderRadius: "12px",
                backgroundColor: "white",
                width: "100%",
                p: 3,
                mt: 3,
                border: dailyLeadLimitCount ? "none" : "1px solid red",
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  color: "#28287B",
                }}
              >
                Email & Phone Number Export Credits <span style={{
                  fontSize: "14px",
                  fontWeight: 400,
                }} >(Per Day)</span>
              </Typography>
              {isLoadingUsage ? (
                <CircularProgress size={25} thickness={5} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "#28287B",
                    }}
                  >
                      {dailyLeadLimit}
                  </Typography>
                </Box>
              )}
              <Box sx={{ width: "100%", mt: 2 }}>
                {" "}
                <LinearProgress
                  variant="determinate"
                  value={100}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: "0px 12px 15px 0px #4B71970D",
                borderRadius: "12px",
                backgroundColor: "white",
                width: "100%",
                p: 3,
                mt: 3,
                border: bounceCreditCount ? "none" : "1px solid red",
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  color: "#28287B",
                }}
              >
                Email Verification Credits
              </Typography>
              {isLoadingUsage ? (
                <CircularProgress size={25} thickness={5} />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{
                      mt: 2,
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: "#28287B",
                    }}
                  >
                      {bounceCredit}
                  </Typography>
                </Box>
              )}
              <Box sx={{ width: "100%", mt: 2 }}>
                {" "}
                <LinearProgress
                  variant="determinate"
                  value={100}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: "0px 12px 15px 0px #4B71970D",
                borderRadius: "12px",
                backgroundColor: "white",
                width: "100%",
                p: 3,
                mt: 3,
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  color: "#28287B",
                  mb: 3,
                }}
              >
                Lead Finder Usage Overview
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  rowGap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: { xs: "100%", sm: "260px" },
                    p: 1.5,
                    border: "1px solid #E4E4E5",
                    borderRadius: "8px",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {" "}
                    <Total />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      width: { xs: "calc(100% - 44px)", sm: "fit-content" },
                      justifyContent: { xs: "space-between", sm: "center" },
                      alignItems: { xs: "center", sm: "flex-start" },
                      flexDirection: { xs: "row-reverse", sm: "column" },
                      ml: 1.5,
                    }}
                  >
                    {isLoadingUsage ? (
                      <CircularProgress size={25} thickness={5} />
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "20px",
                          color: "#28287B",
                          fontWeight: 700,
                          lineHeight: "25.2px",
                        }}
                      >
                        {usage?.leadsCredits > 0 ? credits : 0}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: "13px",
                        lineHeight: "16.38px",
                        color: "#8181B0",
                        mt: 0.5,
                      }}
                    >
                      Total Leads Balance Left
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "row", sm: "column" },
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    width: { xs: "100%", sm: "fit-content" },
                  }}
                >
                  <Button
                    onClick={() => {
                      setLeadCreditDialogOpen(true);
                      setLeadCreditTab("usage");
                    }}
                  >
                    {isMobile ? "Leads Usage" : "View Leads Usage"}
                  </Button>
                  <Button
                    onClick={() => {
                      setLeadCreditDialogOpen(true);
                      setLeadCreditTab("download summary");
                    }}
                  >
                    {isMobile ? "Downloads Summary" : "Lead Finder Downloads Summary"}
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                boxShadow: "0px 12px 15px 0px #4B71970D",
                borderRadius: "12px",
                backgroundColor: "white",
                width: "100%",
                p: 3,
                mt: 3,
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  color: "#28287B",
                  mb: 3,
                }}
              >
                Payment Method
              </Typography>
              {isCardLoading ? (
                <Box
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 10 }}
                >
                  <CircularProgress size={25} thickness={5} />
                  <Typography sx={{ fontSize: "16px", fontWeight: 600, color: "#4e88e6", ml: 2 }}>
                    Loading...
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: { xs: "100%", sm: "40%" },
                    p: 2,
                    border: "1px solid #E4E4E5",
                    borderRadius: "8px",
                    flexDirection: "column",
                    mb: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "13px",
                      lineHeight: "16.38px",
                      color: "#8181B0",
                      mb: 0.5,
                    }}
                  >
                    Card Information
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "20px",
                      color: "#28287B",
                      mb: 3,
                    }}
                  >
                    {payment?.brand?.toUpperCase()} Ending in {payment?.last4}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: "13px",
                      lineHeight: "16.38px",
                      color: "#8181B0",
                      mb: 0.5,
                    }}
                  >
                    Name On Card
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 700,
                      lineHeight: "20px",
                      color: "#28287B",
                    }}
                  >
                    {payment?.name}
                  </Typography>
                </Box>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: { xs: "100%", sm: "40%" },
                }}
              >
                {" "}
                {!isCardLoading ? (
                  <>
                    <Box sx={{ display: 'flex', gap: 0.5 }}>


                      <Button onClick={handelBillingCheckout} disabled={!canEdit || !canDelete} variant='outlined' >
                        Cancel Plan
                      </Button>
                      <Button onClick={handelBillingCheckout} disabled={!canEdit || !canDelete} variant='contained'>
                        Update Credit Card
                      </Button>
                    </Box>

                  </>
                ) : (
                  " "
                )}
              </Box>
            </Box>
            {
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  boxShadow: "0px 12px 15px 0px #4B71970D",
                  borderRadius: "12px",
                  backgroundColor: "white",
                  width: "100%",
                  p: 3,
                  mt: 3,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    color: "#28287B",
                    mb: 3,
                  }}
                >
                  Invoice
                </Typography>
                <Grid
                  container
                  sx={{ width: "100%", maxHeight: "500px", overflowY: "auto", ...scrollBarStyle }}
                >
                  {invoices?.map((invoice) => (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "20px",
                            color: "#28287B",
                          }}
                        >
                          {/* {invoice.createdAt} */}
                          {moment(invoice.createdAt).format("MM/DD/YYYY")}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "50%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button>
                          <Link to={invoice.pdfUrl} target="_blank">
                            Download
                          </Link>
                        </Button>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    borderTop: `1px solid ${theme.palette.grey[300]}`,
                    pt: 2,
                    mt: 2,
                    gap: 2,
                  }}
                >
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: 700, lineHeight: "20px", color: "#28287B" }}
                  >
                    Total Entries :
                  </Typography>{" "}
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {invoices?.length || 0}
                  </Typography>
                </Box>
              </Box>
            }
          </>
        )}
      </Box>
      <UpdatePlanDialog open={open} handleClose={handleClose} />
      <Dialog
        open={leadCreditDialogOpen}
        onClose={() => setLeadCreditDialogOpen(false)}
        sx={{
          backgroundColor: "rgba(4, 4, 30, 0.5)",
          "& .MuiDialog-paper": { height: { xs: "100%", sm: "90vh" } },
        }}
        fullScreen={isMobile}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            width: { xs: "100%", sm: "500px" },
            py: 3,
            px: 3,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "28px",
                letterSpacing: "0em",
                color: "#28287B",
              }}
            >
              {leadCreditTab === "usage" ? "Lead Usage" : "Lead finder download summary"}
            </Typography>
            <IconButton onClick={() => setLeadCreditDialogOpen(false)}>
              <Close />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              mt: 2,
              height: { xs: "85vh", sm: "75vh" },
              overflow: "hidden",
              border: `1px solid ${theme.palette.grey[300]}`,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",

                width: "100%",
                height: "100%",
              }}
            >
              <TableContainer component={Paper} sx={{ height: "100%", ...scrollBarStyle }}>
                <Table aria-label="simple table" sx={{ borderCollapse: "revert" }}>
                  {leadCreditTab === "usage" ? (
                    <TableBody>
                      {leadUsage?.map((item) => (
                        <TableRow
                          key={item?.data?._id}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                letterSpacing: "0em",
                                color: "#8181B0",
                              }}
                            >
                              {item?.data?.amount !== 0 ? item?.data?.amount : -1}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                letterSpacing: "0em",
                                color: "#28287B",
                              }}
                            >
                              {item?.data?.type}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                letterSpacing: "0em",
                                color: "#28287B",
                              }}
                            >
                              {/* {new Date(item?.data?.createdAt).toDateString()} */}
                              {moment(item?.data?.createdAt).format("MM/DD/YYYY")}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      {downloadFiles?.map((item) => (
                        <TableRow
                          key={item._id}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                letterSpacing: "0em",
                                color: "#28287B",
                              }}
                            >
                              {item.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                letterSpacing: "0em",
                                color: "#28287B",
                              }}
                            >
                              {/* {new Date(item.createdAt).toDateString()} */}
                              {moment(item.createdAt).format("MM/DD/YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <IconButton
                              sx={{
                                color: "#28287B",
                              }}
                              onClick={() => handelDowloadCsv(item)}
                            >
                              <DownloadOutlined />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Dialog>
      {fromCreateWorkspace && timelineButtons.length > 1 && (
        <Box sx={{ display: 'flex', mt: 4 }}>
          <Button
            sx={{
              borderColor: '#dc3545',
              color: '#dc3545',
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              "&:hover": {
                borderColor: "#c82333",
                color: "white",
                backgroundColor: "#dc3545",
              },
            }}
            variant='outlined'
            onClick={() => {
              setOpenRemoveWorkspaceDialog(true);
            }}
          >
            Delete workspace
          </Button>
        </Box>
      )}
      <RemoveWorkSpaceDialog
        open={openRemoveWorkspaceDialog}
        onClose={() => {
          setOpenRemoveWorkspaceDialog(false);
        }}
        onClick={handleRemoveWorkspaceClick}
        isLoading={isDeletingWorkspace}
      />
    </>
  );
};

export default BillingAndUsage;
