import { useEffect, useRef, useState } from "react";
import Papa from "papaparse";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CloseTwoTone, CloudUploadTwoTone, TaskAlt } from "@mui/icons-material";
import LinearProgress from "@mui/material/LinearProgress";
import FileUploadCsv from "../emailAccounts/FileUploadCsv.js";
import { UploadColoredIcon } from "src/assets/general/UploadColoredIcon.js";
import { OffCheckboxCustomIcon } from "src/assets/general/OffCheckboxCustomIcon.js";
import { OnCheckboxCustomIcon } from "src/assets/general/OnCheckboxCustomIcon.js";
import {
  useCreateLeadsMutation,
  useDuplicateCheckMutation,
} from "src/services/campaign-service.js";
import { toast } from "react-hot-toast";
import { 
  handleDialogUploadAllClick,
  handleUploadAllClick,
  handleSelectTypeChange,
  updateLeads,
  selections,
  selectionsFilters,
  filterLeads
 } from "./commonFunctions.js";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useBillingUsageQuery } from "src/services/billing-service.js";

const CsvImport = ({ campaign, onLeadsCreate, setSnackbarMsg, setSnackbarOpen, filter=null, onChange = null, setOpenModal=null }) => {
  const fileInputRef = useRef(null);
  const [isDragActive, setIsDragActive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [closeButtonMouseEnter, setCloseButtonMouseEnter] = useState(false);
  const [samples, setSamples] = useState([]);
  const [fileColumns, setFileColumns] = useState([]);
  const [progress, setProgress] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [leads, setLeads] = useState([]);
  const [checkDuplicates, setCheckDuplicates] = useState(true);
  const [verifyLeads, setVerifyLeads] = useState(false);
  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState({});
  const maxSizeInBytes = 20 * 1024 * 1024;
  const [createLeads, { isLoading: isUploading }] = useCreateLeadsMutation();
  const [duplicateCheck, { isLoading: isDupUploading }] = useDuplicateCheckMutation();
  const { data: usage, isLoading: isLoadingUsage, refetch: refetchUsage } = useBillingUsageQuery();
  const [workspaceId, setWorkspaceId] = useState();
  const workspace = useSelector((state) => state.workspace);
  const bounceCredits = usage?.bounceCredit;
  useEffect(() => {
    setWorkspaceId(workspace?._id)
  },[workspace])
  const [selectionValue,setSelectionValue]= useState([])

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragActive(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    handleDragLeave(e)
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    handleFileUpload(file);
  };

  const onLeadsUpdate = (data) => {
    updateLeads({
      data,
      selections,
      filter,
      setLeads
    });
  }

  const onTypeChange = (column, selection, index) => {
    handleSelectTypeChange({
      column, 
      selection,
      index, 
      selectionValue,
      setSelectionValue,
      csvData,
      filter,
      setCsvData,
      onLeadsUpdate,
    });
  }

  

  // const handleFileUpload = (file) => {
  //   setProgress(0);
  //   if (file) {
  //     const allowedTypes = ["text/csv"];

  //     if (allowedTypes.includes(file.type)) {
  //       setSelectedFile(file);

  //       const reader = new FileReader();

  //       reader.onprogress = (event) => {
  //         const { total, loaded } = event;
  //         setProgress((loaded / total) * 100);
  //       };

  //       reader.onload = (event) => {
  //         const csv = event.target.result;
  //         const lines = csv.split(/\r?\n/);
  //         const columns = lines[0].split(",");
  //         setFileColumns(columns);

  //         let isSampleSet = false;

  //         const csvData = [];

  //         for (let i = 1; i < lines.length - 1; i++) {
  //           const line = splitCSVStringWithQuotes(lines[i]);

  //           if (!line.some(Boolean)) continue;

  //           if (!isSampleSet) {
  //             setSamples(line);
  //             isSampleSet = true;
  //           }

  //           const row = [];
  //           for (let j = 0; j < line.length; j++) {
  //             const type =
  //               selections.find((s) => s.label === columns[j])?.value ||
  //               selections[selections.length - 1].value;

  //             if (!line[j]) continue;

  //             row.push({
  //               type,
  //               column: columns[j],
  //               value: line[j],
  //             });
  //           }
  //           csvData.push(row);
  //         }

  //         setCsvData(csvData);
  //         updateLeads(csvData);
  //       };

  //       reader.readAsText(file);
  //     } else {
  //       alert("Only .csv files are allowed.");
  //     }
  //   }
  // };
  
    const handleFileUpload = (file) => {
    setProgress(0);
    if (file) {
      const allowedTypes = ["text/csv"];

      if (file.size > maxSizeInBytes) {
        alert("File length should be less than 20MB");
      } else {
        if (allowedTypes.includes(file.type)) {
          setSelectedFile(file);

          const reader = new FileReader();
          const chunkSize = 10 * 1024; // 1 MB chunks (you can adjust this based on your needs)
          let offset = 0;
          let totalChunkLoaded = 0;
          let content = "";
          reader.onloadend = function () {
            if (reader.error) {
              console.error("Error reading file:", reader.error);
              return;
            }
            let snippet1 = new TextDecoder("utf-8").decode(reader.result);
            // Append the chunk data to the content element
            content += snippet1;
            // Continue reading the next chunk
            if (offset < file.size) {
              readNextChunk();
              totalChunkLoaded = offset;
            } else {
              Papa.parse(content, {
                header: true, // Treat the first row as headers
                skipEmptyLines: true,
                dynamicTyping: true, // Automatically convert numbers and booleans
                complete: (results) => {
                  const columns = results.meta.fields;
                  setFileColumns(columns);
                  const samples = {};
                  for (const col of columns) {
                    const sampleValue = results.data[0][col];
                    samples[col] = sampleValue;
                  }
                  setSamples(samples);
                  const leadsArray = [];
                  for (const result of results.data) {
                    let lead = [];
                    for (const key in result) {
                      if (Object.hasOwnProperty.call(result, key)) {
                        const value = result[key];
                        const datatype = filter ? selectionsFilters.find((filter) => filter.label === key)
                         : selections.find((filter) => filter.label === key);

                         lead.push({
                           column: key,
                           type: datatype ? datatype.value : "DO_NOT_IMPORT",
                           value: value,
                          });
                      }
                    }
                    leadsArray.push(lead);
                  }
                  setCsvData(leadsArray);
                  onLeadsUpdate(leadsArray);
                },
                error: (error) => {
                  console.error("CSV parsing error:", error.message);
                },
              });
            }
          };
          reader.onprogress = function (event) {
            if (event.lengthComputable) {
              var progress = Math.round((totalChunkLoaded / file.size) * 100);
              if (file.size <= chunkSize) progress = 100;
              progress = progress >= 100 ? 100 : progress;
              setProgress(progress);
            }
          };
          function readNextChunk() {
            var blob = file.slice(offset, offset + chunkSize);
            reader.readAsArrayBuffer(blob);
            offset += chunkSize;
          }
          // Start reading the first chunk
          readNextChunk();
        } else {
          alert("Only .csv files are allowed.");
        }
      }
    }
  };
  const handleDeleteFile = () => {
    fileInputRef.current.value = "";
    setSelectedFile(null);
    setCloseButtonMouseEnter(false);
    setProgress(0);
    setFileColumns([]);
    setCsvData([]);
    setLeads([]);
    setSelectionValue([])
  };
  
  const onUploadAllClick = async () => {
    const { validLeads, invalidEmails } = filterLeads(leads);
    await handleUploadAllClick({
      checkDuplicates,
      bounceCredits,
      verifyLeads, 
      leads: validLeads,
      duplicateCheck, 
      setStats, 
      setOpen,
      campaign,
      invalidEmails: invalidEmails,
      totalLeads: leads,
      workspaceId
    });
  };

  const handleInitialSelection = (selection) => {
    setSelectionValue((prevSelections) => [...prevSelections, selection]);
  };

  const handleCompanies = async () => {
    const allCompanyNames = leads.map(obj => obj.company);

    if (allCompanyNames.includes(undefined)) {
      toast.error("Select Company Names to import");
      return;
    }

    onChange('company_name' ,allCompanyNames);
    setOpenModal(false);
  }

  const onUploadClick = () => {
    const { validLeads, invalidEmails } = filterLeads(leads);
    handleDialogUploadAllClick({
      setOpen,
      isUploading,
      setSnackbarOpen,
      setSnackbarMsg,
      leads: validLeads,
      checkDuplicates,
      bounceCredits,
      verifyLeads,
      stats,
      onLeadsCreate,
      campaign,
      createLeads,
      invalidEmails: invalidEmails,
      totalLeads: leads,
      workspaceId
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filteredFileColumns = fileColumns.filter(column => column.trim() !== "");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Paper
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        sx={{
          width: "100%",
          height: "260px",
          borderRadius: "12px",
          border: "1px dashed #0071F6",
          mt: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          boxShadow: "0px 12px 15px 0px #4B71970D",
          backgroundColor: "#F2F4F6",
          padding: '0 10px',
        }}
        onClick={() => {
          !closeButtonMouseEnter && fileInputRef.current.click();
        }}
      >
        <input
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          ref={fileInputRef}
          accept=".csv"
          disabled={isUploading}
        />
        {selectedFile ? (
          <>
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 700,
                lineHeight: "25px",
                color: "#0071F6",
              }}
            >
              Size: {(selectedFile.size / 1000).toFixed(2)} KB
            </Typography>
          </>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 2 }}>
            <UploadColoredIcon />
          </Box>
        )}

        <Typography
          sx={{ fontSize: "20px", fontWeight: 700, lineHeight: "25px", color: "#0071F6" }}
        >
          {selectedFile
            ? selectedFile.name
            : isDragActive
            ? "Drop the files here ..."
            : "Drag files to this area or click to pick files."}
        </Typography>
        {selectedFile && (
          <IconButton
            onMouseEnter={() => {
              setCloseButtonMouseEnter(true);
            }}
            onMouseLeave={() => {
              setCloseButtonMouseEnter(false);
            }}
            sx={{ position: "absolute", right: 0, top: 0, m: 1 }}
            onClick={handleDeleteFile}
            disabled={isUploading}
          >
            <CloseTwoTone sx={{ color: "black" }} />
          </IconButton>
        )}
        {selectedFile && (
          <Box sx={{ width: "80%", mt: 2, display: progress === 100 && "none" }}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
        )}
      </Paper>
      {selectedFile && progress === 100 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              mt: 2,
            }}
          >
            <TaskAlt sx={{ color: "rgb(33, 111, 237)", mr: 1 }} />
            <Typography sx={{ fontWeight: 600, color: "rgb(33, 111, 237)" }}>
              Files Processed
            </Typography>
          </Box>
          <TableContainer sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width={360}>Column Name</TableCell>
                  <TableCell width={360}>Select Type</TableCell>
                  <TableCell>Samples</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFileColumns.map((column, index) => (
                  <FileUploadCsv
                    key={index}
                    column={column}
                    sample={samples[column]}
                    selections={filter === true ? selectionsFilters :  selections}
                    onChange={(s) => onTypeChange(column, s, index)}
                    isUploading={isUploading}
                    alreadySelectionValues={selectionValue}
                    setSelectionValue={setSelectionValue}
                    filter={filter}
                    isSelected={csvData?.length > 0 && Boolean(csvData[0]?.find(cur => cur.type !== "DO_NOT_IMPORT" && cur.column === column))}
                    samples = {samples}
                    onInitialSelection={handleInitialSelection}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {!filter ? (<Box
            sx={{
              textAlign: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={checkDuplicates}
                  onChange={(e, value) => setCheckDuplicates(value)}
                />
              }
              label="Check for duplicates across all campaigns"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "#28287B",
                },
                mt: 2,
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  icon={<OffCheckboxCustomIcon />}
                  checkedIcon={<OnCheckboxCustomIcon />}
                  checked={verifyLeads}
                  onChange={(e, value) => setVerifyLeads(value)}
                />
              }
              label="Validate Emails"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "#28287B",
                },
                mt: 2,
              }}
            />
          </Box>) : <></> }
          
          <Button
            variant="contained"
            sx={{
              fontSize: "16px",
              px: 3,
              py: 1.5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
            onClick={filter ? handleCompanies : checkDuplicates ? onUploadAllClick : onUploadClick}
          >
            {isUploading || isDupUploading ? (
              <CircularProgress size={20} sx={{ color: "white", mr: 1 }} />
            ) : (
              <CloudUploadTwoTone sx={{ mr: 1 }} />
            )}
            Upload All
          </Button>
        </>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "25px", color: "#595959", textAlign: "center" }}>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ fontSize: "16px", fontWeight: "500" }}>
            This will upload{" "}
            <Typography component="span" color={"blue"}>
              {stats.uploadedCount}{" "}
            </Typography>{" "}
            contacts to your campaign .{" "}
            <Typography component="span" color={"blue"}>
              {" "}
              {stats?.emailCampaignCount || stats?.duplicateEmailsCount}{" "}
            </Typography>{" "}
            contacts had errors.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <Button
            onClick={onUploadClick}
            variant="contained"
            sx={{
              fontSize: "16px",
              borderRadius: "12px",
              px: 2.5,
              py: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            {isUploading || isDupUploading ? (
              "Uploading..."
            ) : (
              <>
                <CloudUploadTwoTone sx={{ mr: 1 }} />
                Upload
              </>
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              // backgroundColor: "#595959",
              color: "Black",
              fontSize: "16px",
              borderRadius: "12px",
              px: 3.2,
              py: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
              "&:hover": {
                backgroundColor: "#787878",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CsvImport;
