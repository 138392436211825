import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress,
} from "@mui/material";
import DataNotFoundImage from "../../assets/no_data_found_image.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDomainIpBlockListMutation } from "src/services/tool-service.js";

const DomainIpBlockListPage = () => {
  const [domainDetails, setDomainDetails] = useState([]);
  const [domainIpBlockList] = useDomainIpBlockListMutation();
  const validationSchema = () => {
    let schema = Yup.object({
      domain: Yup.string()
        .max(255)
        .required("Domain is required")
        .matches(
          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
          "Please add a valid Domain address"
        ),
    });

    return schema;
  };
  const dnsblServices = ["spamhaus", "spamcop", "barracudacentral", "dnsbl", "dsbl"];

  const handleDomainDataSubmit = async (values, helpers) => {
    try {
      const result = await domainIpBlockList(values).unwrap();

      if (result.message === "Fetched Error") {
        setDomainDetails([{ noDomainFound: `${formik?.values?.domain}: Invalid Domain Name ` }]);
      } else {
        setDomainDetails([{ blockListArray: result.blacklistStatus.blacklistStatus }]);
      }
    } catch (err) {
      helpers.setErrors({ submit: err.data.error.message });
    }
  };

  const formik = useFormik({
    initialValues: {
      domain: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await handleDomainDataSubmit(values, helpers, true);
      } catch (err) {
        helpers.setErrors({ submit: err.data.error.message });
      }
      formik.resetForm();
    },
  });

  return (
    <Box sx={{ px: { sm: "7%" }, py: { sm: 5 }, p: 4, fontFamily: "Inter" }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", color: "primary.main" }}>
        Domain/IP Blacklist Checker Tool
      </Typography>
      <Typography color="primary.main" sx={{ fontSize: "0.8rem" }}>
        Determine whether the domain is blacklisted by providing its IP address or domain name.
      </Typography>

      {/* domain/Ip BlockList Form */}
      <form noValidate onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            flexDirection: { xs: "column", sm: "row" },
            mt: { xs: "24px", sx: "24px", md: 3, lg: 3, xl: 3 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: { sm: 1 },
              flexDirection: { xs: "column", sm: "row" },
              mb: { xs: 0, sm: 0 },
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <TextField
              variant="outlined"
              placeholder="Enter a domain or Ip address"
              onChange={formik.handleChange}
              error={!!(formik.touched.domain && formik.errors.domain)}
              helperText={formik.touched.domain && formik.errors.domain}
              onBlur={formik.handleBlur}
              name="domain"
              value={formik?.values?.domain}
              sx={{
                borderColor: "gray.300",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  width: { md: "21vw", sm: "35vw", xs: "auto" },
                  maxHeight: "44px",
                  color: "primary.main",
                },
                mb: { xs: 0, sm: 0 },
                paddingBottom: { xs: "8px", sm: "11px" },
                paddingRight: { sm: "0px" },
              }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            sx={{
              ml: [0, 4],
              px: 4,
              py: 2,
              width: { sm: "fit-content", xs: "100%" },
              padding: "10px",
              marginLeft: { xs: "7px", sm: "4" },
              marginTop: { xs: 2, sm: 0 },
              maxHeight: "44px",
              cursor: formik?.values?.domain ? "pointer" : "not-allowed",
            }}
            disabled={!formik?.values?.domain}
            type="submit"
          >
            {formik.isSubmitting ? <CircularProgress size={20} color="inherit" /> : <>Look up</>}
          </Button>
        </Box>
      </form>

      {domainDetails.length !== 0 && (
        <Box mt={{ xs: 4, sm: 6 }} mb={4}>
          <Paper
            elevation={2}
            sx={{
              backgroundColor: "#d6e9c6",
              padding: 2,
              borderRadius: "12px",
              color: "#3c763d",
            }}
          >
            <Typography variant="h5" mb={1}>
              Summary
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Typography sx={{ fontSize: "0.9rem" }}>
                  <strong>Total Tests:</strong> 5
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography sx={{ fontSize: "0.9rem" }}>
                  <strong>Total Blacklist:</strong> 0
                </Typography>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography sx={{ fontSize: "0.9rem" }}>
                  <strong>Total Tested OK:</strong> 5
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}

      {/* result Table */}
      <Box
        sx={{
          height: "auto",
          mt: { sm: 6, xs: 6 },
          border: 8,
          borderColor: "#ECEDF5",
          borderRadius: "8px",
        }}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow
                sx={{
                  backgroundColor: "#F6F6FA",
                  color: "#9295A7",
                  fontSize: "1.2rem",
                  fontWeight: "medium",
                }}
              >
                <TableCell sx={{ px: 2, py: 2 }}>STATUS</TableCell>
                <TableCell sx={{ px: 2, py: 2 }}>TEST</TableCell>
                <TableCell sx={{ px: 2, py: 2 }}>REASON</TableCell>
                <TableCell sx={{ px: 2, py: 2 }}>RESPONSE TIME</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {domainDetails.length === 0 ? (
                <>
                  <TableRow>
                    <TableCell colSpan={5} sx={{ px: 2, py: 2, textAlign: "center" }}>
                      <Typography
                        color="primary.main"
                        sx={{ mt: { sm: "5vh", xs: "2vh" }, fontSize: "0.8rem" }}
                      >
                        Simply enter a domain or IP address, click &quot;Look up&quot;, and get
                        instant results.
                      </Typography>
                      <Box sx={{ display: "flex", justifyContent: "center", mt: "10vh" }}>
                        <img src={DataNotFoundImage} alt="Doc Icon" />
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {Array.isArray(domainDetails[0]?.blockListArray) &&
                    domainDetails[0].blockListArray.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <CheckCircleIcon sx={{ color: "green", verticalAlign: "middle" }} />{" "}
                          <Typography component="span" sx={{ fontWeight: "bold" }}>
                            OK
                          </Typography>
                        </TableCell>
                        <TableCell>{dnsblServices[index]}</TableCell>
                        <TableCell>- </TableCell>
                        <TableCell>{row.responseTime} ms</TableCell>
                      </TableRow>
                    ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default DomainIpBlockListPage;
