import React, { useState } from "react";
import { useAuth } from "src/auth/auth.js";
import SettingTab from "./SettingTab.js";
import WarmupTab from "./WarmupTab.js";
import { HighlightOff } from "@mui/icons-material";
import { Box, IconButton, Typography, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BulkSettingEmailDrawer = ({ accountIds, onClose, tab }) => {
  const { canEdit } = useAuth();
  const [value, setValue] = useState(
    tab === "campaign" ? 0 : tab === "warmup" ? 1 : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const account = {
    customDomain: {
      isEnable: false,
      name: undefined,
    },
    warmup: {
      basicSetting: {
        slowWarmupDisabled: false,
        alertBlock: true,
      },
      advanceSetting: {
        weekdayOnly: false,
        readEmulation: false,
        customTrackingDomain: false,
      },
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "50vw",
          p: 3,
          px: 7,
        }}
      >
        <Box sx={{ display: "flex", width: "100%", flexDirection: "row-reverse" }}>
          <IconButton onClick={onClose}>
            <HighlightOff fontSize="large" />
          </IconButton>
        </Box>
        <Typography
          sx={{
            fontSize: "17px",
            fontWeight: 600,
            color: "black",
            textAlign: "left",
            width: "100%",
            ml: 4,
          }}
        >
          Bulk Settings
        </Typography>
        <Box sx={{ p: 3, width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="fullWidth"
            sx={{ borderRadius: "8px" }}
          >
            <Tab
              label="Campaign Settings"
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
              }}
              {...a11yProps(0)}
            />
            <Tab
              label="Warmup Settings"
              sx={{
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
              }}
              {...a11yProps(1)}
            />
          </Tabs>
          <Box
            sx={{
              mb: 4,
              mt: 3,
              width: "100%",
            }}
          >
            <CustomTabPanel value={value} index={0}>
              <SettingTab bulkUpdate accountIds={accountIds} account={account} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <WarmupTab bulkUpdate accountIds={accountIds} account={account} canEdit={canEdit} />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BulkSettingEmailDrawer;